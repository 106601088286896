<template>
  <v-container fill-height class="py-10" style="align-content: center;">
    <!--<v-row justify="center" class="py-5">
      <h1 class="font-weight-black">ANÁLISE PRESCRIÇÃO INTERCORRENTE</h1>
    </v-row>-->
    <v-row justify="center">
      <v-container>
        <v-row justify="center">
          <v-img src="../assets/quati_final.png" alt="logo quaTI" max-width="300"></v-img>
        </v-row>
        <v-row justify="center">
          <v-col xs="12" sm="8" md="8" lg="6">
            <v-text-field label="Digite o número do Processo para a análise da prescrição" v-mask="'#######-##.####.#.##.####'"
              v-model.lazy="nprocesso" 
              rounded 
              solo
              append-icon="mdi-send"
              @click:append="requestPost"
              @keydown.enter="requestPost"
              ref="input-nprocesso"
              :counter="25" :rules="[
                checkInput812,
                checkInputLength25,
                checkYearConditions
              ]">
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <v-row class ="pb-6" style="justify-content: center;">
      <v-col><process-list></process-list></v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from "axios"
  import ProcessList from "../components/ProcessList.vue";
  
  // Declaração das constantes axios(func)/url do back
  const urlbackend = process.env.VUE_APP_API_URL

  export default {
    components:{
        ProcessList,
    },
    data() {
      return {
        nprocesso: '',
        processArray: [],
      }
    },
    created() {
    if(JSON.parse(localStorage.getItem("user")))
    this.config = {
      headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).tokens.access.token}` },
      timeout: 600000
    };
  },
    methods: {
      async requestPost() {
        if (this.fieldValidator()) {
          const onlyNumbers = this.nprocesso.replace(/\D/g,'')
          await axios.post(urlbackend+'/process', {numberProcess: onlyNumbers},this.config).finally(()=>{
            this.$router.push('process/' + onlyNumbers)
          this.salvarValor(this.nprocesso)
          })
          // TRATAR A RESPONSE PARA MUDAR ROTA SOMENTE EM CASO DE SUCESSO
          
        }
      },
      // Verifica se a entrada possui o parametro 8.12 que por padrão tem que ter em todo processo
      checkInput812(nprocesso) {
        if (nprocesso.indexOf('8.12') === 16) {
          return true;
        } else {
          return 'O número do processo não contém 8.12 por padrão'
        }
      },
      // Verifica se a entrada tem tamanho 25, que por padrão eh o tamanho do processo
      checkInputLength25() {
        if (this.nprocesso.length === 25) {
          return true;
        }
        else {
          return 'Tamanho inválido!'
        }
      },
      findAndConvertYear(nprocesso) {
        // Coleta o ano do processo
        var initialIndex = nprocesso.indexOf('.') + 1
        var finalIndex = nprocesso.indexOf('.', initialIndex) + 1
        var yearStringProcess = nprocesso.substring(initialIndex, finalIndex + 4)
        
        // Transformando str em int
        var yearIntProcess = parseInt(yearStringProcess, 10)
        return yearIntProcess;
      },
      checkYearConditions(nprocesso) {
        var yearProcess = this.findAndConvertYear(nprocesso)
        var currentYear = new Date().getFullYear()

        if (yearProcess < 1949) {
          return "Ano do processo inválido"
        } else if (yearProcess > currentYear + 1) {
          return "Ano do processo inválido"
        } else {
          return true;
        }
      },
      // Valida se as funções chamadas estão retornando corretas no input
      fieldValidator() {
        return this.$refs["input-nprocesso"].$data.valid
      },
      salvarValor(newProcess) {
        if (this.processArray.filter((process) => process == newProcess).length == 0) {
          this.processArray.push(newProcess)
          localStorage.setItem("oldProcesses", JSON.stringify(this.processArray))
        }
      }
    },
  mounted() {
    const oldProcesses = JSON.parse(localStorage.getItem("oldProcesses"))
    if (oldProcesses) {
      this.processArray = oldProcesses
    }
  }
}
</script>