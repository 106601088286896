import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

import Vue from 'vue'
import VueMask from 'v-mask'
import CKEditor from '@ckeditor/ckeditor5-vue2'

Vue.config.productionTip = false
Vue.use(VueMask)
Vue.use(CKEditor)

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
