<template>
    <v-container dense>
        <v-menu offset-y :close-on-content-click="false" left style="z-index: 20;">
            <template v-slot:activator="{ on }">
                <v-btn  depressed icon class="mr-2" v-on="on">
                    <v-badge dark color="white" overlap :content="unreadNotifications" :value="unreadNotifications" v-on="on">
                        <template v-slot:badge>
                             <span class="custom-badge-text">{{ unreadNotifications }}</span>
                             </template>
                        <v-icon color="amber lighten-1">
                            {{ unreadNotifications > 0 ? 'mdi-bell' : 'mdi-bell-outline' }}
                            </v-icon>
                    </v-badge></v-btn>
            </template>
            <v-card class="scrollable-card" >
                <v-card-title style="justify-content: space-between; padding: 5px;">
                    <div class="mr-5">Notificações</div>
                    <div v-if="unreadNotifications > 0"><v-chip dark color="primary">
                            {{ unreadNotifications }}
                            <span class="ml-1" v-if="unreadNotifications == 1"> notificação não
                                lida</span>
                            <span class="ml-1" v-else> notificações não lidas</span>
                        </v-chip>
                    </div>
                </v-card-title>
                <v-tabs v-model="tab">
                    <v-tab href="#prescricao">Prescrição Intercorrente</v-tab>
                    <v-tab href="#chati">ChaTI</v-tab>
                    <v-tab href="#uniRH">UniRH</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <div class="pa-2">
                        <v-tab-item value="prescricao">
                            <v-row style="justify-content: space-around;" class="py-2 ml-2 mr-2">
                                <v-col cols="6">
                                    <v-select outlined :items="ordemBusca" v-model="selecao" style="width: fit-content;"
                                        class="py-2" hide-details dense>
                                    </v-select>
                                </v-col>
                                <v-col cols="6" class="pl-2" style="align-content: center;">
                                    <v-btn-toggle v-model="chooseFilter" >
                                        <v-btn small :value="0">
                                            Todos
                                        </v-btn>

                                        <v-btn small :value="1">
                                            Não lidos
                                        </v-btn>

                                        <v-btn small :value="2">
                                            Lidos
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>
                            <div v-if="filteredNotifications.length">
                                <v-card v-for="item of filteredNotifications" :key="item.id">
                                    <v-card-title class="pr-2" style="justify-content: space-around;">
                                        <v-icon class="pr-2">mdi-book-alert-outline</v-icon>
                                        <span class="pr-2" style="font-size: initial;">Aviso de prescrição:
                                            Processo
                                            {{ item.numberProcess }}</span>
                                        <span style="font-size: initial;">
                                            {{  (diffDays(item.dateAlert) === 0)? 'Hoje':`Há ${ diffDays(item.dateAlert)} dias` }} <v-icon color="primary"
                                                v-if="!item.readConfirm">mdi-circle-medium</v-icon></span>
                                    </v-card-title>
                                    <v-card-text >
                                        <span v-html="item.message.replace('h1', 'h3')">
                                        </span>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="deleteNotifications(item)"><v-icon
                                                color="error">mdi-trash-can</v-icon></v-btn>
                                        <v-btn icon @click="markUnread(item)" v-if="item.readConfirm === true"><v-icon
                                                color="primary"> mdi-email-mark-as-unread</v-icon></v-btn>
                                        <v-btn icon @click="postReadnotification(item)" v-if="!item.readConfirm"><v-icon
                                                color="green">mdi-check-circle-outline</v-icon></v-btn>
                                    </v-card-actions>
                                </v-card>

                            </div>
                            <div v-else style="text-align: center">
                                <span> Não há mensagens</span>
                            </div>
                        </v-tab-item>
                    </div>
                    <v-tab-item value="chati">
                        <v-row style="justify-content: space-around;" class="py-2 ml-2 mr-2">
                                <v-col cols="6">
                                    <v-select outlined :items="ordemBusca" v-model="selecao" style="width: fit-content;"
                                        class="py-2" hide-details dense>
                                    </v-select>
                                </v-col>
                                <v-col cols="6" class="pl-2" style="align-content: center;">
                                    <v-btn-toggle v-model="chooseFilter">
                                        <v-btn small :value="0">
                                            Todos
                                        </v-btn>

                                        <v-btn small :value="1">
                                            Não lidos
                                        </v-btn>

                                        <v-btn small :value="2">
                                            Lidos
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>
                        <div v-if="filteredNotifications.length">
                                <v-card v-for="item of filteredNotifications" :key="item.id">
                                    <v-card-title class="pr-2" style="justify-content: space-around;">
                                        <v-icon class="pr-2">mdi-chat-question-outline</v-icon>
                                        <span class="pr-2" style="font-size: initial;">Aviso de prescrição:
                                            Processo
                                            {{ item.numberProcess }}</span>
                                        <span style="font-size: initial;">
                                            {{  (diffDays(item.dateAlert) === 0)? 'Hoje':`Há ${ diffDays(item.dateAlert)} dias` }} <v-icon color="primary"
                                                v-if="!item.readConfirm">mdi-circle-medium</v-icon></span>
                                    </v-card-title>
                                    <v-card-text>
                                        {{ item.message }}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="deleteNotifications(item)"><v-icon
                                                color="error">mdi-trash-can</v-icon></v-btn>
                                        <v-btn icon @click="markUnread(item)" v-if="item.readConfirm === true"><v-icon
                                                color="primary"> mdi-email-mark-as-unread</v-icon></v-btn>
                                        <v-btn icon @click="postReadnotification(item)" v-if="!item.readConfirm"><v-icon
                                                color="green">mdi-check-circle-outline</v-icon></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </div>
                            <div v-else style="text-align: center">
                                <span> Não há mensagens</span>
                            </div>
                            
                    </v-tab-item>
                    <v-tab-item value="uniRH">
                        <v-row style="justify-content: space-around;" class="py-2 ml-2 mr-2">
                                <v-col cols="6">
                                    <v-select outlined :items="ordemBusca" v-model="selecao" style="width: fit-content;"
                                        class="py-2" hide-details dense>
                                    </v-select>
                                </v-col>
                                <v-col cols="6" class="pl-2" style="align-content: center;">
                                    <v-btn-toggle v-model="chooseFilter">
                                        <v-btn small :value="0">
                                            Todos
                                        </v-btn>

                                        <v-btn small :value="1">
                                            Não lidos
                                        </v-btn>

                                        <v-btn small :value="2">
                                            Lidos
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>
                        <div v-if="filteredNotifications.length">
                                <v-card v-for="item of filteredNotifications" :key="item.id">
                                    <v-card-title class="pr-2" style="justify-content: space-around;">
                                        <v-icon class="pr-2">mdi-account-supervisor</v-icon>
                                        <span class="pr-2" style="font-size: initial;">Aviso de prescrição:
                                            Processo
                                            {{ item.numberProcess }}</span>
                                            <span style="font-size: initial;" >
                                              {{  (diffDays(item.dateAlert) === 0)? 'Hoje':`Há ${ diffDays(item.dateAlert)} dias` }} <v-icon color="primary"
                                                v-if="!item.readConfirm">mdi-circle-medium</v-icon>
                                            </span>
                                    </v-card-title>
                                    <v-card-text >
                                        <span v-html="item.message">
                                        </span>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn icon @click="deleteNotifications(item)"><v-icon
                                                color="error">mdi-trash-can</v-icon></v-btn>
                                        <v-btn icon @click="markUnread(item)" v-if="item.readConfirm === true"><v-icon
                                                color="primary"> mdi-email-mark-as-unread</v-icon></v-btn>
                                        <v-btn icon @click="postReadnotification(item)" v-if="!item.readConfirm"><v-icon
                                                color="green">mdi-check-circle-outline</v-icon></v-btn>
                                    </v-card-actions>
                                </v-card>
                            </div>
                            <div v-else style="text-align: center">
                                <span>
                                Não há mensagens
                            </span>
                            </div>
                            
                    </v-tab-item>
                </v-tabs-items>

            </v-card>
        </v-menu>
    </v-container>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

const urlbackend = process.env.VUE_APP_API_URL

export default {
    data() {
        return {
            tab: "prescricao",
            selecao: 'Mais recentes',
            ordemBusca: [
                "Mais recentes",
                "Mais antigas",
            ],
            notificationArray: [],
            chooseFilter: 0,
        }
    },

    computed: {
        filteredNotifications() {
            var filtered = this.notificationArray

            const applicationsNames = {
                "prescricao":"Prescrição Intercorrente", 
                "chati":"ChaTI", 
                "uniRH": "RH"
                }

            if (filtered.length == 0) {
                return filtered
            }
            else {
                filtered = filtered.filter((el) => el.application === applicationsNames[this.tab])
            }


            if (this.chooseFilter == 2) {
                return filtered.filter((el) => el.readConfirm === true)
            }
            else if (this.chooseFilter == 1) {
                return filtered.filter((el) => el.readConfirm === false)
            }
            else if (this.chooseFilter == 0) {
                return filtered
            }

            if (this.selecao === "Mais recentes" || this.selecao === "Mais antigas") {
                return filtered.sort((a, b) => {
                    const [diaA, mesA, anoA] = a.dateAlert.split('/').map(Number);
                    const [diaB, mesB, anoB] = b.dateAlert.split('/').map(Number);

                    const dataA = new Date(anoA, mesA - 1, diaA);
                    const dataB = new Date(anoB, mesB - 1, diaB);

                    if (this.selecao === "Mais recentes") return dataB - dataA;
                    else return dataA - dataB;
                })
            }

            return filtered
        },

        unreadNotifications() {
            var unreads = {}
            unreads = this.notificationArray.filter((el) => el.readConfirm === false)
            return unreads.length
        },

    },

    methods: {
        markUnread(item) {
            axios.post(urlbackend + "/notification/unread", { idNotification: item.id, readConfirm: false }).then((res) => {
                if (res.status === 200) {
                    item.readConfirm = false
                }
            }).catch((err) => {
                console.log(err)
            })

        },

        deleteNotifications(item) {
            axios.delete(urlbackend + "/notification/" + item.id).then((res) => {
                if (res.status === 200) {
                    this.notificationArray = this.notificationArray.filter((el) => el.id !== item.id)
                }
            }).catch((err) => {
                console.log(err)
            })
        },

        postReadnotification(item) {
            axios.post(urlbackend + "/notification/unread", { idNotification: item.id, readConfirm: true }).then((res) => {
                if (res.status === 200) {
                    item.readConfirm = true
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        getNotifications() {
            axios.get(urlbackend + "/notification").then((res) => {
                this.notificationArray = res.data
                this.notificationArray.map((item) => { item.dateAlert = new Date(item.dateAlert).toLocaleDateString('pt-BR') })
            }).catch((err) => {
                console.log(err)
            })
        },
        diffDays(date) {
            let hoje = new Date().toLocaleDateString('pt-br')
            const dataAlerta = moment(date, 'DD/MM/YYYY').add(1, 'days'); // Adiciona 1 dia
            const dataHoje = moment(hoje, 'DD/MM/YYYY');
            const diffDias = dataHoje.diff(dataAlerta, 'days');
            return diffDias

        }
    },
    created() {
        this.getNotifications()
    }
}

</script>

<style>
.scrollable-card {
    max-height: 400px;
    /* Ajuste conforme necessário */
    overflow-y: auto;
    /* Habilita o scroll vertical se o conteúdo exceder a altura máxima */
}
.custom-badge-text {
  color: blue; /* Altere para a cor desejada */
}

.notifications-button-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999; /* Valor alto para garantir que fique acima de outros elementos */
  background-color: white; /* Opcional: para garantir que o fundo seja visível */
}

</style>