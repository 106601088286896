<template>
  <v-container style="margin-bottom: 80px" :class="insideChat? 'popup-container':''">
    <v-row class="text-left" v-for="(message, index) in messages" :key="index"
      :justify="message.sender == 'bot' ? 'start' : 'end'" align="center" :style="insideChat? `align-content: baseline;`: ''">

      <v-col cols="10" sm="8" md="6" v-if="message.sender == 'options'">
        <v-sheet class="blue-grey lighten-5 py-4 px-8 text-justify" style="border-radius: 20px">
          <v-btn class="px-2" text color="primary" v-for="option of message.options" :key="'options_' + option"
            @click="message.action(option)">{{ option }}</v-btn>
        </v-sheet>
      </v-col>

      <v-col cols="2" sm="2" v-if="message.sender == 'options'">
        <UserAvatar></UserAvatar>
      </v-col>

      <v-col cols="2" sm="2" v-if="message.sender == 'bot'">
        <v-avatar style="border:solid; color:#039BE5">
          <img class="pa-1" :src="quati_logo" alt="Logo do Quati">
        </v-avatar>
      </v-col>

      <v-col cols="10" sm="10" v-if="message.sender == 'bot'">
        <v-sheet class="blue-grey lighten-5 py-4 px-8 text-justify" style="border-radius: 20px">
          <span v-html="formattedText(message.text)"></span>
        </v-sheet>
      </v-col>

      <v-col cols="10" sm="8" md="6" v-if="message.sender == 'user'">
        <v-sheet class="blue-grey lighten-5 py-4 px-8 text-justify" style="border-radius: 20px">
          <span v-html="formattedText(message.text)"></span>
        </v-sheet>
      </v-col>

      <v-col cols="2" sm="2" v-if="message.sender == 'user'">
        <UserAvatar></UserAvatar>
      </v-col>
    </v-row>
    <v-row align="center" v-if="loadingResponse">
      <v-col cols="2" sm="2">
        <v-avatar style="border:solid; color:#039BE5">
          <img class="pa-1" :src="quati_logo" alt="Logo do Quati">
        </v-avatar>
      </v-col>
      <v-col cols="6" sm="3">
        <v-sheet class="blue-grey lighten-5 py-4 px-8 text-justify" style="border-radius: 20px">
          <v-progress-linear buffer-value="0" color="black" reverse stream></v-progress-linear>
        </v-sheet>
      </v-col>
    </v-row>
    <v-footer :fixed="!insideChat" :style="insideChat?'position:sticky;bottom:0px':'bottom:0px;'" class="transparent">
      <v-container class="py-0">
        <!-- <v-row class="py-0 px-4" justify="center">
            <v-sheet
            v-if="!docInterfaceActive"
            color="blue-grey lighten-5"
            height="25"
            rounded
            width="80%"
            class="px-2"
          >
            <span class="text-caption">Buscando no Processo</span>
          </v-sheet>
          </v-row> -->
        <!-- <v-row class="pt-0 pb-2 px-2" ref="textArea"> -->
        <v-row class="pa-2 mt-3" ref="textArea">
          <v-textarea ref="area" class="pt-2 pb-2 px-2" background-color="blue-grey lighten-5" style="border-radius: 20px;"
            hint="One row" auto-grow solo hide-details rows="1" row-height="9" max-height="300px" append-icon="mdi-send"
            v-model="text" @click:append="sendMessage()">
            <template v-slot:prepend-inner style="margin: 0px !important">
              <!-- <span class="text-caption">{{item}}</span> -->
              <v-menu offset-y v-if="docInterfaceActive">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn large  icon elevation="1" color="white" v-bind="attrs" v-on="on"
                    style="background-color: #1976d2">
                    <v-icon>mdi-file-document-alert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(item, index) in documentOptions" :key="index">
                    <v-btn text @click="item.action">{{ item.title }}</v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-textarea>
        </v-row>
      </v-container>
    </v-footer>
  </v-container>
</template>

<script>
import quati_logo from '@/assets/quati.png'
import UserAvatar from './UserAvatar.vue'
const urlbackend = process.env.VUE_APP_API_URL
import axios from 'axios'
// import * as utils from '@/utils/formatter.js'
export default {
  name: 'Chati',
  components: {
    UserAvatar
  },
  props: {
    document: {
      type: String,
      required: false,
      default: ''
    },
    insideChat: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  mounted() {
    this.bottomPadding = this.$refs.textArea.style.height

    if (!this.document) {
      this.messages = [{
        sender: 'bot', text: `Olá, eu sou o <strong>QuaTI</strong>.\n Você pode me perguntar informações presentes nos <strong>Pareceres Referenciais</strong> da PGE-MS entre <strong>2018 e 2023</strong>.
        Além disso, você também pode me pedir para que eu resuma um processo ou busque informações dentro dele.

           Por exemplo, me pergunte:
            <strong>"Qual Parecer trata sobre cessão de 'bens móveis?'"</strong>

            Ou me peça um resumo do processo de um processo: 
            <strong>"Faça um resumo do processo *Número do Processo*'"</strong>`},]
      }
      else{
        this.messages = [{
          sender: 'bot', text: `Olá, eu sou o <strong>QuaTI</strong>.\n Você pode me perguntar informações sobre o documento <strong>${this.document}</strong>`},
        ]
      }
  },
  data: (vm) => ({
    loadingResponse: false,
    quati_logo: quati_logo,
    text: '',
    bottomPadding: '80',
    docInterfaceActive: false,
    messages: [
    ],
    documentOptions: [
      { title: 'Parar de buscar dentro do processo', action: () => { vm.quitDocumentSearch() } }
    ],
    lastProcess: ''
  }),
  methods: {
  
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    formattedText(text) {
      return text.replace(/\n/g, '<br>')
    },
    
    async activateResumo(option) {
      this.messages.pop()
      if (option == 'sim') {
        this.docInterfaceActive = true
        
        this.messages.push({ sender: 'user', text: 'Sim' })
        await this.sleep(1000)
        this.messages.push({ sender: 'bot', text: 'Ok, você já pode fazer perguntas sobre o processo ' + this.lastProcess })
      } else {
        this.messages.push({ sender: 'user', text: 'Não' })
        await this.sleep(1000)
        this.docInterfaceActive = false
        this.messages.push({ sender: 'bot', text: 'Ok, estou à disposição para ajudar em outras questões.' })
      }
    },
    async quitDocumentSearch() {
      this.docInterfaceActive = false
      this.messages.push({ sender: 'user', text: 'Gostaria de fazer perguntas sobre outros assuntos.' })
      this.loadingResponse = true
      await this.sleep(1000)
      this.loadingResponse = false
      this.messages.push({ sender: 'bot', text: 'Ok, estou à disposição para ajudar você em outras questões.' })
    },
    sendMessage() {
      if (this.text) {
        this.loadingResponse = true
        this.messages.push({ sender: 'user', text: this.text })
        let userMessage = this.text
        this.text = ''
        
        if(this.document) {
          axios.post(urlbackend + '/llm/chati/' + this.document, { model: 'LlamaTest2', message: userMessage.replace("'", ""), wait_scraper: true }).then(
            (res) => {
              console.log(res.data)
              this.loadingResponse = false
              this.messages.push({ sender: 'bot', text: res.data.response })
            }
          )
        }
        else if (this.docInterfaceActive) {
          this.messages.push({ sender: 'bot', text: 'Buscando no processo...' })

          axios.post(urlbackend + '/llm/chat/' + this.lastProcess, { model: 'LlamaTest2', message: userMessage.replace("'", ""), wait_scraper: true }).then(
            (res) => {
              this.messages.pop()
              console.log(res.data)
              this.loadingResponse = false
              window.scrollTo(0, document.body.scrollHeight + 80);
              this.messages.push({ sender: 'bot', text: res.data.response })
            }
          )
        } else {
          axios.post(urlbackend + '/llm/chat/', { model: 'LlamaTest2', message: userMessage.replace("'", ""), wait_scraper: false }).then(
            (res) => {
              console.log(res.data)
              this.loadingResponse = false
              window.scrollTo(0, document.body.scrollHeight + 80);
              this.messages.push({ sender: 'bot', text: res.data.response })
              if (res.data.modo == 'RESUMO' && !res.data.try_again) {
                this.messages.push({ sender: 'bot', text: 'Quer perguntar algo mais sobre o processo?' })
                this.messages.push({ sender: 'options', options: ['sim', 'não'], 'action': (option) => this.activateResumo(option)  })
                this.lastProcess = res.data.numberProcess
              }
              if (res.data.try_again) {
                this.loadingResponse = true
                axios.post(urlbackend + '/llm/chat/', { model: 'LlamaTest2', message: userMessage.replace("'", ""), wait_scraper: true }).then(
                  (res) => {
                    console.log(res.data)
                    this.loadingResponse = false
                    window.scrollTo(0, document.body.scrollHeight + 80);
                    this.messages.push({ sender: 'bot', text: res.data.response })
                    this.lastProcess = res.data.numberProcess
                    if (res.data.modo == 'RESUMO') {
                      this.messages.push({ sender: 'bot', text: 'Quer perguntar algo mais sobre o processo?' })
                      this.messages.push({ sender: 'options', options: ['sim', 'não'], 'action': (option) => this.activateResumo(option) })
                    }
                  }
                )
              }
            }
          )
        }
      }
    }
  }
}
</script>

<style>
.v-input__prepend-inner {
  margin: 0px !important;
}

.v-textarea textarea {
  max-height: 200px !important
}

.popup-container {
  height: 100%;
  background-color: #000000;
  padding-bottom: 40px;
  margin: 10px;
  border-radius: 20px;
}

</style>