<template>
  <v-container fluid fill-height class="py-0 my-0">
    <v-row v-if="!processAnalised" class="py-0 my-0 px-0">
      <v-col cols="12" style="text-align: center; align-self: end">
        <v-progress-circular :size="70" :width="7" color="blue" indeterminate v-if=!error>
        </v-progress-circular>
        <v-icon :size="70" :width="7" color="red" :value="100" v-else class="mdi mdi-alert">
        </v-icon>
      </v-col>
      <v-col v-if="!error" cols="12" style="text-align: center;align-self: start;">
        <span v-if="processo.inScrapping == true">Buscando dados no ESAJ...</span>
        <span v-else>Classificando movimentações do Processo.... {{ valorPorcentagem }}%</span>
        <br>
        <span color="blue"><strong>Esse procedimento pode levar até 2 minutos</strong></span>
      </v-col>
      <v-col v-else cols="12" style="text-align: center;align-self: start;">
        <span>Ocorreu um erro na busca do processo... :{{ '(' }} </span>
        <br>
        <span color="blue"><strong>Volte para a página inicial</strong></span>
        <br>
        <p></p>
        <v-btn size="large" color="primary" @click="$router.push(`/`)"><v-icon color="white">mdi-home</v-icon></v-btn>
      </v-col>
    </v-row>

    <v-row v-if="processAnalised && $vuetify.breakpoint.smAndUp" style="height: 100%">
      <v-col cols="7">
        <timeline-process :key="timelineRef" :process="processo" :showAll="false" :debug="debug" @updateValidate="updateValidate"  @updatePrescriptionDate="updatePrescriptionDate"></timeline-process>
      </v-col>
      <v-divider vertical style="background-color: rgb(162, 162, 162)"></v-divider>
      <v-col cols="5" class="pa-2">
        <information-process 
          :process="processo"
          @updateValidate="updateValidate"
          @updatePrescriptionDate="updatePrescriptionDate"
          :userPrescriptionDate="userPrescriptionDate"
          @updateViewMode="updateViewMode"
          >
        </information-process>
      </v-col>
    </v-row>
    <v-row v-if="processAnalised && $vuetify.breakpoint.xsOnly" style="height: 100%">
      <v-tabs v-model="tab" align-tabs="center">
        <v-tab v-for="tab_item in tabs" :key="tab_item">
          <span>{{ tab_item }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="height:100%">
          <v-tab-item v-for="tab_item in tabs" :key="tab_item" >
            <timeline-process v-if="tab_item == 'Movimentações'" :process="processo" :showAll="false" :debug="debug" @updateValidate="updateValidate" @updatePrescriptionDate="updatePrescriptionDate"></timeline-process>
            <information-process v-if="tab_item == 'Informações'" :process="processo" @updateValidate="updateValidate" @updateViewMode="updateViewMode" :userPrescriptionDate="userPrescriptionDate"></information-process>
          </v-tab-item>
        </v-tabs-items>
    </v-row>
  </v-container>
</template>

<script>
import InformationProcess from '../components/InformationProcess.vue'
import TimelineProcess from '../components/TimelineProcess.vue'
import axios from 'axios'

const urlbackend = process.env.VUE_APP_API_URL

export default {
  components: {
    InformationProcess,
    TimelineProcess
  },
  props: {
    numberProcess: {
      type: String,
      required: true
    },
    debug: {
      type: String,
      required: false
    }
  },
  data: () => ({
    items: [
      {
        icon: 'mdi-file-document'
      }
    ],
    processAnalised: false,
    processo: {
      unificado: false // Valor padrão para unificado
    },
    error: false,
    //debug: false,
    getProcessIntervalID: null,
    tab: null,
    tabs: ['Movimentações','Informações'],
    userPrescriptionDate: null,
    timelineRef: 0,
    cleanView: null,
    originalProcess: null
  }),
  async created() {
    this.getProcess(this.$route.params.numberProcess)
    //this.updateViewMode()
    //this.debug = this.props.debug 
  },
  computed: {
    valorPorcentagem() {
      var count = 0
      if (this.processo.counterFlag > 0) {
        count = 100 - (this.processo.counterFlag * 100 / 120)
        return Math.floor(count)
      } else {
        return 100
      }
    }
  },
  unmounted() {
    if (this.getProcessIntervalID)
      clearInterval(this.getProcessIntervalID)
  },
  methods: {
    updateViewMode(mode) {
      if(mode == 'clean') {
        this.originalProcess = this.processo
        this.processo.movements = this.processo.movements.map((item) => {
          return { ...item, statusTemporary: [], prescriptionMovement: null, important: false, expand: false }
        })
        this.timelineRef++
        this.cleanView = true
        //this.$refs
        //this.processo = JSON.parse(JSON.stringify({ ...this.processo, movements: movements }))
      } else {
        this.processo = this.originalProcess
        this.timelineRef++
        this.cleanView = false
      }
    },
    getAllAttachedinScrapping(processo) {
      var arrayApensados = []
      if (processo.attachedTo != null) arrayApensados.push(processo.attachedTo)
      if (processo.attached != null) arrayApensados.push(...processo.attached)
      return arrayApensados.filter((item) => {
        return item.inScrapping
      })
    },
    updatedAttachedandGetCounter() {
      var arrayApensadosinScrapping = this.getAllAttachedinScrapping(this.processo)
      if (arrayApensadosinScrapping.length == 0 && this.processo.counterFlag <= 0 && !this.processo.inScrapping) {
        this.processAnalised = true
        clearInterval(this.getProcessIntervalID)
      } else {
        axios.get(urlbackend + '/process/' + this.processo.numberProcess, { timeout: 300000 }).then(
          (res) => {
            this.processo = res.data
            if (!this.processo.inScrapping && this.processo.counterFlag <= 0) {
              this.processAnalised = true
              this.processo.attachedTo = res.data.attachedTo
              this.processo.attached = res.data.attached
              this.processo.distributionYear = res.data.distribution.split(" ")[0]
            }
          }).catch((err) => {
            console.log('Falha na análise do processo, Erro:\n', err)
            this.error = true
          })
      }
    },
    async getProcess(numberProcess) {
      axios.get(urlbackend + '/process/' + numberProcess, { timeout: 300000 }).then(
        (process) => {
          if (process.data) {
            this.processo = process.data
            if (this.processo.counterFlag <= 0 && !this.processo.inScrapping) {
              this.processAnalised = true
            }
            
            this.getProcessIntervalID = setInterval(this.updatedAttachedandGetCounter, 3000, this.processo)
            setTimeout(() => {
              this.error = true
              clearInterval(this.getProcessIntervalID)
            }, 300000); 
          }
        }
      ).catch((err) => {
        console.log('Falha na análise do processo, Erro:\n', err)
        this.error = true
      })
    },
    updateValidate(newStatus) {
      if (this.processo.validated != newStatus) {
        if (!newStatus) {
          axios.delete(urlbackend + '/process/validate/' + this.processo.numberProcess).then(
            (res) => {
              if(res.data)
              this.processo.validated = false
            }).catch((err) => {
              console.log('Falha na validação do processo, Erro:\n', err)
            })
        } else {
          axios.post(urlbackend + '/process/validate/' + this.processo.numberProcess).then(
            (res) => {
              if(res.data)
              this.processo.validated = true
            }).catch((err) => {
              console.log('Falha na validação do processo, Erro:\n', err)
            })
        }
      }
    },
    updatePrescriptionDate(newDate){
      this.userPrescriptionDate = newDate
    }
  }
}
</script>
