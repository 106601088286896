<template>
  <v-data-table style="width: 100%;" :headers="headers" :items="items" item-key="name" class="elevation-1">
    <!--ACTIONS-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.actions="{ item }">
      <div class="d-flex flex-no-wrap">
        <v-icon v-if="item === items[items.length - 2] && item.active === true && !item.is_editing && !item.exoneration"
          size="x-large" color="red" @click="exonerateItem('icon', item)" title="Exonerar">
          mdi-alert-octagon-outline
        </v-icon>
        <v-menu bottom left v-if="item !== items[items.length - 1] && !item.is_editing">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon :class="{'pl-12': item !== items[items.length - 2] || !item.active}" v-if="item.active === true" color="black" size="x-large">mdi-dots-vertical
              </v-icon>
              <v-icon v-else class="pl-12" color="black" size="x-large">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteItem(item)">
              <v-list-item-title>Deletar</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!--ícone para cancelar edição-->
        <v-icon v-if="item.is_editing" size="x-large" color="red" @click="notEditItem(item)" title="Cancelar">
          mdi-cancel
        </v-icon>
        <v-icon v-if="item.is_editing" class="pl-1" size="x-large" color="green" @click="updateCargo(item)" title="Confirmar">
          mdi-check-circle
        </v-icon>
      </div>
      <v-icon class="pl-7" v-if="item === items[items.length - 1]" size="x-large" color="green" @click="registraCargo(item)">
        mdi-plus-circle
      </v-icon>
    </template>
    <!-- Campos de entrada para informações do cargo -->

    <!--CARGO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.post="{ item }">
      <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.post" required label="Cargo"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.post
          }}</span>
      </template>
    </template>
    <!--Vínculo-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.bond="{ item }">
      <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.bond" required label="Vínculo"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.bond
          }}</span>
      </template>
    </template>
    <!--JOBREGISTER-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.jobRegister="{ item }">
      <v-text-field auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.jobRegister" v-mask="'##########'" label="Matrícula"
        :rules="[rules.required]"></v-text-field>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.jobRegister
          }}</span>
      </template>
    </template>

    <!--DEPARTAMENTO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.department="{ item }">
      <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.department" required label="Setor"
        :rules="[rules.required]">
      </v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.department
          }}</span>
      </template>
    </template>

    <!--FUNÇÃO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.role="{ item }">
      <v-textarea rows="1" auto-grow width="" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.role" label="Função"
        :rules="[rules.required]" @focus="onFocus('role')" @blur="onBlur('role')">
      </v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.role
          }}</span>
      </template>
    </template>

    <!--DATA DE ENTRADA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.entryDate="{ item }">
      <v-text-field v-if="item === items[items.length - 1] || item.is_editing" v-model="item.entryDate" label="Admissão"
        v-mask="'##/##/####'" :rules="[rules.required, validateDate]"></v-text-field>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{
          formatDate(item.entryDate) }}</span>
      </template>
    </template>

    <!--EXONERAÇÃO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.exoneration="{ item }">
      <v-text-field v-if="item === items[items.length - 1] || item.is_editing" v-model="item.exoneration" label="Exoneração"
        v-mask="'##/##/####'" :rules="[isExonerationDateValid]"></v-text-field>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{
          formatDate(item.exoneration) }}</span>
      </template>
    </template>

    <!--CHEFIA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.isBoss="{ item }">
      <v-select v-if="item === items[items.length - 1] || item.is_editing" v-model="item.isBoss" :items="isBossSelector" label="Chefia"
        :rules="[rules.required]"></v-select>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.isBoss ?
          'Sim' : 'Não' }}</span>
      </template>
    </template>

    <!--SETOR DE CHEFIA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.bossSector="{ item }">
      <v-textarea rows="1" auto-grow v-if="(item === items[items.length - 1] || item.is_editing)  && item.isBoss == 'Sim' " v-model="item.bossSector" required label="Setor que chefia"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.bossSector
          }}</span>
      </template>
    </template>

    <!--SIMBOLO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.symbol="{ item }">
      <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.symbol" required label="Símbolo"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.symbol
          }}</span>
      </template>
    </template>

    <!--CCA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.cca="{ item }">
      <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.cca" required label="CCA"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.cca
          }}</span>
      </template>
    </template>

    <!--CIDADE DE LOTAÇÃO-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.workCity="{ item }">
      <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.workCity" required label="Cidade Lotação"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.workCity
          }}</span>
      </template>
    </template>

    <!--ORGÃO DE ORIGEM-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.originOrganization="{ item }">
      <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.originOrganization" required label="Orgão origem"
        :color="item.color" :rules="[rules.required]"></v-textarea>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.originOrganization
          }}</span>
      </template>
    </template>

    <!--ABONO DE PERMANÊNCIA-->
    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:item.bonusPostRetirement="{ item }">
      <v-select v-if="item === items[items.length - 1] || item.is_editing" v-model="item.bonusPostRetirement" :items="bonusPostRetirementSelector" label="Abono Permanência"
        :rules="[rules.required]"></v-select>
      <template v-else>
        <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.bonusPostRetirement ?
          'Sim' : 'Não' }}</span>
      </template>
    </template>
  </v-data-table>
</template>

<script>

const urlbackend = process.env.VUE_APP_API_URL
import axios from 'axios'

export default {
  watch: {
    person: {
      handler() {
        this.fetchCareerData()
      },
      deep: true,
      immediate: true
    },
    items: {
      handler(newItems) {
        newItems.forEach(item => {
          if (item.isBoss === 'Não') {
            item.bossSector = '';
          }
        });
      },
      deep: true,
    },
  },
  props: {
    value: Array,
    person: Object,
    update: Boolean,
  },
  data() {
    return {
      // Objeto para armazenar o item que está sendo editado
      copia_item: {},
      isBossSelector: ["Sim", "Não"],
      bonusPostRetirementSelector: ["Sim", "Não"],
      rules: {
        required: (value) => !!value || "Campo Obrigatório"
      },
      fetchedPerson: {},
      headers: [
        { text: 'Matrícula', value: 'jobRegister', sortable: false, align:'center', width: '120px' },
        { text: 'Cargo', value: 'post', sortable: false, width: '100px', align:'center' },
        { text: 'Vinculo', value: 'bond', sortable: false, width: '100px', align:'center' },
        { text: 'Setor', value: 'department', sortable: false, width: '100px', align:'center' },
        { text: 'Função', value: 'role', sortable: false, align:'center', width: '130px' },
        { text: 'Admissão', value: 'entryDate', sortable: false , width:'120px', align:'center'},
        { text: 'Exoneração', value: 'exoneration', sortable: false,  width: '120px', align:'center' },
        { text: 'Chefia', value: 'isBoss', sortable: false },
        { text: "Setor que Chefia", value: "bossSector", sortable: false, width: '130px', align:'center' },
        { text: "Símbolo", value: "symbol", sortable: false },
        { text: "CCA", value: "cca", sortable: false, width: '100px', align:'center' },
        { text: "Cidade Lotação", value: "workCity", sortable: false,  width: '130px', align:'center' },
        { text: "Orgão Origem", value: "originOrganization", sortable: false,  width: '130px', align:'center' },
        { text: "Abono de Permanência", value: "bonusPostRetirement", sortable: false,  width: '160px', align:'center' },
        // { text: "Ramal", value: "ramal", sortable: false },
        { text: "Ações", value: "actions", sortable: false }

        
      ],
      items: [
        {
          jobRegister: '',
          post: '',
          department: '',
          role: '',
          bond: '',
          entryDate: '',
          exoneration: '',
          isBoss: '',
          bossSector: '',
          cca: '',
          symbol: '',
          workCity: '',
          originOrganization: '',
          bonusPostRetirement: '',
          is_editing: false,
          is_disabled: false
        }
      ],
    }
  },
  mounted() {
    this.items = this.person.careers.map((el) => {
              el.is_disabled = false;
              el.is_editing = false;
              return el;
            });
            // Adiciona o objeto para um novo cargo no final do array
            this.items.push({
              jobRegister: '',
              post: '',
              department: '',
              role: '',
              bond: '',
              entryDate: '',
              exoneration: '',
              isBoss: '',
              cca: '',
              bossSector: '',
              symbol: '',
              workCity: '',
              originOrganization: '',
              bonusPostRetirement: '',
              is_editing: false,
              is_disabled: false
            });
  },
  methods: {
    onFocus(column) {
      const header = this.headers.find(header => header.value === column);
      //console.log("A header encontrada foi " + header.value)
      if (header) {
        header.class = 'expanded-header';
      }
    },
    onBlur(column) {
      const header = this.headers.find(header => header.value === column);
      if (header) {
        header.class = '';
      }
    },

    // Tira o formato ISO da data para exibir pro usuário (##/##/####)
    formatDate(value) {
      if (!value) return ''
      var date = new Date(value)
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      date = new Date(date.getTime() + userTimezoneOffset);
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
      
    },
    validateDate(value) {
      const [day, month, year] = value.split('/').map(Number);

      // Verifique se o mês é válido
      if (month < 1 || month > 12) {
        return "Mês inválido.";
      }
      // Verifique o dia com base no mês
      const daysInMonth = [31, (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (day < 1 || day > daysInMonth[month - 1]) {
        return "Dia inválido.";
      }
      return true;
    },
    isExonerationDateValid(exonerationDate) {
      const item = this.items.find(i => i.is_editing || i === this.items[this.items.length - 1]);
      if (!item) return true;

      const [dayEx, monthEx, yearEx] = exonerationDate.split('/').map(Number);
      const [dayAd, monthAd, yearAd] = item.entryDate.split('/').map(Number);
      const exoneration = new Date(yearEx, monthEx - 1, dayEx);
      const admission = new Date(yearAd, monthAd - 1, dayAd);

      if (exoneration < admission) {
        return "Data de exoneração anterior a data de admissão"
      }

      return true
    },
    async fetchCareerData() {
      var urlGet = urlbackend + '/rh/person/'
      if (this.person?.ID) {
        urlGet = urlbackend + '/rh/person/' + this.person.ID

        axios.get(urlGet).then((res) => {
          if (res.status == 200 && res.data) {
            if (this.$parent || this.$root) {
              let person = res.data;
              person.birthday = new Date(person.birthday);
              this.fetchedPerson = person;
            }
            this.items = res.data.careers.map((el) => {
              el.is_disabled = false;
              el.is_editing = false;
              return el;
            });
            // Adiciona o objeto para um novo cargo no final do array
            this.items.push({
              jobRegister: '',
              post: '',
              department: '',
              role: '',
              bond: '',
              entryDate: '',
              exoneration: '',
              isBoss: '',
              cca: '',
              bossSector: '',
              symbol: '',
              workCity: '',
              originOrganization: '',
              postRetirementBonus: '',
              is_editing: false,
              is_disabled: false
            });
          }
        });
      } else {
        this.items = [
          {
            jobRegister: '',
            post: '',
            department: '',
            role: '',
            bond: '',
            entryDate: '',
            exoneration: '',
            isBoss: '',
            cca: '',
            bossSector: '',
            symbol: '',
            workCity: '',
            originOrganization: '',
            postRetirementBonus: '',
            is_editing: false,
            is_disabled: false
          }
        ]
      }
    },
    // Atualiza o cargo -> Atualiza os campos do cargo
    updateCargo(item) {
      if(item.jobRegister === '' || item.post === '' || item.department === '' || item.role === '' || item.bond === '' || item.entryDate === '' || item.isBoss === '' || item.cca === '' || item.symbol === '' || item.workCity === '' || item.originOrganization === '' || item.bonusPostRetirement === '') {
        alert('Preencha todos os campos obrigatórios.');
        return;
      }
      if (confirm("Tem certeza que deseja atualizar este item?")) {

        // Garante que a data de admissão esteja em formato ISO antes de enviar
        if (item.entryDate) {
          const [day, month, year] = item.entryDate.split('/');
          item.entryDate = new Date(`${year}-${month}-${day}`);
        }

        // Garante que a data de exoneração esteja em formato ISO antes de enviar
        if (item.exoneration) {
          const [day, month, year] = item.exoneration.split('/');
          item.exoneration = new Date(`${year}-${month}-${day}`)
        }
        else {
          item.exoneration = null;
        }

        //Transforma o isBoss em booleano para enviar ao backend
        if (item.isBoss === "Sim") {
          item.isBoss = true
        } else {
          item.isBoss = false
        }

        //Transforma o bonusPostRetirement em booleano para enviar ao backend
        if (item.bonusPostRetirement === "Sim") {
          item.bonusPostRetirement = true
        } else {
          item.bonusPostRetirement = false
        }

        //Retira os campos que não são necessários para enviar ao backend
        const cargoToSend = { ...item };
        delete cargoToSend.is_editing;
        delete cargoToSend.is_disabled;
        delete cargoToSend.createdAt;
        delete cargoToSend.updatedAt;
        delete cargoToSend.ID;
        delete cargoToSend.user;
        
        //Faz com que o exoneration não seja mandado para o backend, caso um cargo não tenha essa data.
        if (!cargoToSend.exoneration)
          delete cargoToSend.exoneration;


        //Envia o cargo atualizado ao backend - PUT
        axios.put(urlbackend + '/rh/career/' + item.ID, cargoToSend).then(async (res) => {
          if (res.status >= 200 && res.status <= 299) {
            item.is_editing = false
            await this.fetchCareerData();
            this.$emit('update-careers', this.fetchedPerson.ID);
          }
        }).catch((error) => {
          console.error('Erro ao atualizar o cargo:', error);
          alert('Ocorreu um erro ao atualizar o cargo. Por favor, tente novamente.');
        });
      }
    },
    deleteItem(item) {
      if (confirm("Tem certeza que deseja excluir este item?")) {
        axios.delete(urlbackend + '/rh/career/' + item.ID).then((res) => {
          if (res.status >= 200 && res.status <= 299) {
            const index = this.items.indexOf(item);
            if (index > -1) {
              this.items.splice(index, 1);
            }
          }
        }).catch((error) => {
          console.error('Erro ao excluir o item:', error);
          alert('Ocorreu um erro ao excluir o item. Por favor, tente novamente.');
        });
      }
    },
    exonerateItem(event, item) {
      if (event === 'icon' && confirm("Tem certeza que deseja exonerar este cargo?")) {
        item.is_disabled = true;
        if (!item.exoneration) {
          item.exoneration = new Date();
        }
        let body = {
          active: item.active,  // Mantém o active como true
          bond: item.bond,
          department: item.department,
          entryDate: item.entryDate,
          exoneration: item.exoneration,
          isBoss: item.isBoss,
          jobRegister: item.jobRegister,
          personId: item.personId,
          post: item.post,
          role: item.role,
          cca: item.cca,
          bossSector: item.bossSector,
          symbol: item.symbol,
          workCity: item.workCity,
          originOrganization: item.originOrganization,
          bonusPostRetirement: item.bonusPostRetirement
        };
        axios.put(urlbackend + '/rh/career/' + item.ID, body)
          .then(async (res) => {
            if (res.status >= 200 && res.status <= 299) {
              // Atualize a lista de itens
              await this.fetchCareerData();
              this.$emit('update-careers', this.fetchedPerson.ID);
              //console.log("Cargo exonerado com sucesso!");
            }
          })
          .catch((error) => {
            console.error('Erro ao exonerar o cargo:', error);
            alert('Ocorreu um erro ao exonerar o cargo. Por favor, tente novamente.');
          });
      }
    },
    editItem(item) {
      //Copia o item para caso o usuário cancele a edição
      this.copia_item = { ...item }
      item.is_editing = true

      //Converte o isBoss para visualização do usuário
      item.isBoss = item.isBoss ? "Sim" : "Não";

      //Converte o bonusPostRetirement para visualização do usuário
      item.bonusPostRetirement = item.bonusPostRetirement ? "Sim" : "Não";

      //Converte as datas para o formato dd/mm/yyyy
      item.exoneration = this.formatDate(item.exoneration);
      item.entryDate = this.formatDate(item.entryDate);
      
    },
    notEditItem(item) {
      if (confirm("Tem certeza que deseja cancelar a edição?")) {
        //Retorna os valores originais do item, antes da alteração do usuário.
        item.jobRegister = this.copia_item.jobRegister
        item.post = this.copia_item.post
        item.department = this.copia_item.department
        item.role = this.copia_item.role
        item.bond = this.copia_item.bond
        item.entryDate = this.copia_item.entryDate
        item.exoneration = this.copia_item.exoneration
        item.isBoss = this.copia_item.isBoss
        item.cca = this.copia_item.cca
        item.bossSector = this.copia_item.bossSector
        item.symbol = this.copia_item.symbol
        item.workCity = this.copia_item.workCity
        item.originOrganization = this.copia_item.originOrganization
        item.bonusPostRetirement = this.copia_item.bonusPostRetirement
        item.is_disabled = this.copia_item.is_disabled
        item.is_editing = false
      }
    },
    registraCargo(item) {
      if(item.jobRegister === '' || item.post === '' || item.department === '' || item.role === '' || item.bond === '' || item.entryDate === '' || item.isBoss === '') {
        alert('Preencha todos os campos obrigatórios.');
        return;
      }
      if (confirm("Tem certeza que deseja registrar este cargo e exonerar o cargo anterior?")) {
        if (item.entryDate) {
          const [day, month, year] = item.entryDate.split('/');
          item.entryDate = `${year}-${month}-${day}`;
        }
        if (item.exoneration) {
          const [day, month, year] = item.exoneration.split('/');
          item.exoneration = `${year}-${month}-${day}`;
        }
        const cargo = {
          jobRegister: item.jobRegister,
          post: item.post,
          department: item.department,
          role: item.role,
          bond: item.bond,
          entryDate: item.entryDate,
          is_disabled: false,
          is_editing: false,
          isBoss: item.isBoss === "Sim" ? true : false,
          cca: item.cca,
          bossSector: item.bossSector,
          symbol: item.symbol,
          workCity: item.workCity,
          originOrganization: item.originOrganization,
          bonusPostRetirement: item.bonusPostRetirement === "Sim" ? true : false,
          active: true,
          personId: this.person.ID,
        }
        this.items.splice(this.items.length - 1, 0, cargo);
        const cargoToSend = { ...cargo };
        delete cargoToSend.is_editing;
        delete cargoToSend.is_disabled;

        //Envia o cargo ao backend - POST
        axios.post(urlbackend + '/rh/career', cargoToSend).then(async (res) => {
          if (res.status >= 200 && res.status <= 299) {
            cargo.ID = res.data.ID;

            // Exonera o cargo anterior
            this.exonerateItem('', this.items[this.items.length - 3]);

            // Atualiza a lista de cargos, para exibir o cargo exonerado
            await this.fetchCareerData();
            this.$emit('update-careers', this.fetchedPerson.ID);
            // Limpa os campos
            this.clean_fields(item);
          }
        }).catch((error) => {
          console.error('Erro ao registrar o cargo:', error)
        });
        this.clean_fields(item)
      }
    },

    //Limpa os campos do cargo
    clean_fields(item) {
      item.jobRegister = '';
      item.post = '';
      item.department = '';
      item.role = '';
      item.entryDate = '';
      item.exoneration = '';
      item.isBoss = '';
      item.bond = '';
      item.cca = '';
      item.bossSector = '';
      item.symbol = '';
      item.workCity = '';
      item.originOrganization = '';
      item.bonusPostRetirement = '';
    }
  }
}
</script>