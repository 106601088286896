<template>
  <v-col cols="auto" class="pa-0 pr-2">
      <p class="mb-0" style="font-size: 13px; color: rgb(115, 114, 114)">{{ title }}</p>
      <p style="font-size: 13px">{{ value }}</p>
  </v-col>
</template>

<script>
export default {
  props: {
    title: String,
    value: String
  }
}
</script>

<style></style>