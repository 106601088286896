<template>
  <v-card v-if="dialog">
    <v-toolbar color="primary" dark style="justify-content: center">
      Notificação personalizada
      <v-spacer></v-spacer>
      <v-icon color="amber lighten-1">mdi-bell</v-icon>
    </v-toolbar>
    <v-card-title>
      <v-row>
        <v-span class="custom-font-size">
          Programe o processo <v-span class="blue-text">{{ numberProcess }}</v-span> para ser exibido em suas notificações:
        </v-span>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row class="mt-0">
        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
        offset-y min-width="auto" :open-on-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="formattedDate" label="Data para ser notificado" prepend-icon="mdi-calendar"
              readonly v-bind="attrs" v-on="on" @click="menu2 = !menu2">
            </v-text-field>
          </template>
          <v-date-picker v-model="dateInput" @input="onDateSelected">
          </v-date-picker>
        </v-menu>
      </v-row>
      <v-row>
        <v-textarea filled color="primary" label="Observação:" v-model="observacao"></v-textarea>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end pt-0">
      <v-btn text color="red" @click="closeDialog">Fechar</v-btn>
      <v-btn color="primary" @click="salvarNotification">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'

const urlbackend = process.env.VUE_APP_API_URL

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    numberProcess: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu2: false,
      dateInput: '',
      formattedDate: '',
      observacao: '',
      aplicacao: 'Prescrição Intercorrente',
      formattedNumberProcess: this.formatProcessNumber(this.numberProcess)
    }
  },
  watch: {
    dateInput(newValue) {
      this.formattedDate = this.formatDate(newValue);
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialog', false)
    },
    salvarNotification() {
      if (!this.dateInput) {
        alert('Por favor, preencha a data para a notificação.')
        return
      }

      // Montando o objeto pra ser enviado
      const dataToSend = {
        numberProcess: this.formattedNumberProcess,
        application: this.aplicacao,
        dateAlert: this.dateInput,
        message: this.observacao,
      }

      axios.post(urlbackend + '/notification', dataToSend)
        .then(() => {
          // Limpar campos ou executar outras ações após sucesso
          this.dateInput = ''
          this.observacao = ''
          this.closeDialog() // Fechar o diálogo após salvar
        })
        .catch(error => {
          console.error('Erro ao criar notificação:', error)
        })
    },
    onDateSelected(value) {
      this.menu2 = false
      this.formattedDate = this.formatDate(value)
    },
    formatDate(date) {
      if (!date) return ''
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Tirando pontos e traços do numero do processo
    formatProcessNumber(number) {
      return number.replace(/[.-]/g, '')
    }
  }
}
</script>

<style scoped>
.blue-text {
  color: #0288D1
}
.custom-font-size {
  font-size: 0.900rem
}
</style>