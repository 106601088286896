<template>
  <v-container class="py-0">
    <v-row>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="service.nameService" label="Nome" filled clearable
          :rules="[rules.required]"></v-text-field>
      </v-col>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="service.userRegistration" label="Matricula" filled></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="formattedBirthDate" type="date" label="Data de nascimento"
          filled></v-text-field>
      </v-col>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="service.userAge" readonly label="Idade" filled></v-text-field>
      </v-col>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="service.userProcess" label="Processo N°" filled></v-text-field>
      </v-col>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="service.userCapacity" label="Lotação" filled></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="service.userCareer" label="Carreira" filled></v-text-field>
      </v-col>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="service.userBoard" label="Quadro" filled></v-text-field>
      </v-col>
      <v-col cols="12" sm="">
        <v-text-field hide-details v-model="service.userFunction" label="Função" filled></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="">
        <v-text-field v-model.lazy="inicialDateService" @change="toDate('inicial')" type="date" label="Data inicio"
          filled :rules="[validationInicial]"></v-text-field>
      </v-col>
      <v-col cols="12" sm="">
        <v-text-field v-model.lazy="finalDateService" @change="toDate('final')" type="date" label="Data fim" filled
          :rules="[validationFinal]"></v-text-field>
      </v-col>
      <v-col cols="12" sm="">
        <v-text-field v-model="daysService" hide-details readonly label="Dias" filled></v-text-field>
      </v-col>
      <v-radio-group v-model="row" row @change="choose(row)" class="py-3">
        <v-radio label="Averbação" value="averbacao"></v-radio>
        <v-radio label="Faltas" value="faltas"></v-radio>
        <v-radio label="Licenças" value="licencas"></v-radio>
        <v-radio label="Suspensões" value="suspensoes"></v-radio>
      </v-radio-group>
    </v-row>
    <v-row class="mt-1">
      <v-col align="end" class="py-0">
        <v-btn outlined color="primary" @click="clearAll">Gerar Novo Relatório</v-btn>
        <v-btn class="ml-2" color="primary" @click="submit" :disabled="isDisabled">Adicionar período</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import servidores from "../assets/servidores_pge.json"

export default {
  props: {
    service: Object
  },
  data() {
    return {
      servidores: servidores.servidores,
      worker: { name: "" },
      birthDate: "",
      userProcess: "",
      inicialDateService: "",
      finalDateService: "",
      isaverbacaoService: false,
      isfaltasService: false,
      islicencasService: false,
      issuspensoesService: false,
      daysService: "",
      inicialDate: 0,
      finalDate: 0,
      row: null,
      today: new Date(),
      // Variaveis para auxilio
      validationInicial: true,
      validationFinal: true,
      validatedBtn: false,
      items: ["Pública", "Privada"],
      rules: {
        required: value => !!value || 'Campo vazio.',
      }
    }
  },
  watch: {
    service: {
      handler() {
        if (this.service.userBirthDate) {
          const formattedDate = this.formatDateToYMD(this.service.userBirthDate);
          this.birthDate = formattedDate;  // Atribui à variável local, sem atualizar o service
          this.calculateAge(this.birthDate);  // Atualiza a idade com base na data formatada
        }
      },
      deep: true
    }
  },
  computed: {
    isDisabled() {
      // Desabilitar se nome, data inicial ou data final estiverem vazios
      if (this.service.nameService === "" || this.inicialDateService === "" || this.finalDateService === "" || this.row === null) {
        return true;
      }
      // Desabilitar se a data inicial for maior que a final
      if (this.inicialDate > this.finalDate) {
        return true;
      }
      return false;
    },
    formattedBirthDate() {
      return this.service.userBirthDate ? this.formatDateToYMD(this.service.userBirthDate) : '';
    },
  },
  created() {
    if (this.service.userBirthDate) {
      this.birthDate = this.formatDateToYMD(this.service.userBirthDate); 
    }
  },
  methods: {
    formatDateToYMD(date) {
      if (!date) return '';
      const [day, month, year] = date.split('/');
      return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    },
    calculateAge(birthDate) {
      if (!birthDate) return;
      const birthDateObj = new Date(birthDate);
      let age = this.today.getFullYear() - birthDateObj.getFullYear();
      const monthDifference = this.today.getMonth() - birthDateObj.getMonth();

      // Corrige a idade se o aniversário ainda não foi atingido este ano
      if (monthDifference < 0 || (monthDifference === 0 && this.today.getDate() < birthDateObj.getDate())) {
        age--;
      }

      this.service.userAge = age;
    },
    changeOnService() {
      // Relaciona as variaveis (iguala)
      if (this.worker) {
        this.service.nameService = this.worker.name
        this.service.userRegistration = this.worker.register
        //this.service.userBirthDate = this.worker.birthday
        //this.service.userAge = this.worker.
        this.service.userClass = this.worker.symbol
        this.service.userCode = this.worker.code
        this.service.userBoard = this.worker.bond
        this.service.userCareer = this.worker.post
        this.service.userFunction = this.worker.role
        this.service.userCapacity = this.worker.department

        if (this.worker.birthday || !(this.worker.birthday !== this.birthDate)) {
          let date = this.worker.birthday.split('/')
          if (date[2] > '50') {
            date[2] = '19' + date[2]
          } else {
            date[2] = '20' + date[2]
          }
          this.service.userBirthDate = date[1] + '/' + date[0] + '/' + date[2]
          this.birthDate = date[2] + '-' + parseInt(date[0]).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) + '-' + parseInt(date[1]).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        } else {
          this.service.userBirthDate = ''
        }
      } else {
        this.service.nameService = ''
        this.service.userRegistration = ''
        this.service.userClass = ''
        this.service.userCode = ''
        this.service.userBoard = ''
        this.service.userCareer = ''
        this.service.userFunction = ''
        this.service.userCapacity = ''
        this.birthDate = ''
        this.service.userBirthDate = ''
      }

    },
    submit() {
      // Adiciona os inputs nas variaveis
      this.$emit("add-period", this.inicialDateService, this.finalDateService, this.isaverbacaoService, this.isfaltasService, this.islicencasService, this.issuspensoesService, this.daysService)
    },
    clear() {
      // Limpa (apaga) todos os valores dos campos do períodos
      this.inicialDateService = ""
      this.finalDateService = ""
      this.isaverbacaoService = false
      this.daysService = ""
      this.isfaltasService = false
      this.islicencasService = false
      this.issuspensoesService = false
      this.row = null // variavel que guarda o valor da opcao escolhida

      // Valores da tabela
      this.service.yearlyInfo = []
    },
    clearAll() {
      // Limpa (apaga) todos os valores dos campos
      this.worker = ""
      this.service.userRegistration = ""
      this.birthDate = ""
      this.service.userAge = ""
      this.service.userProcess = ""
      this.service.userCapacity = ""
      this.service.userCareer = ""
      this.service.userBoard = ""
      this.service.userFunction = ""
      this.service.trabdays = 0
      this.inicialDateService = ""
      this.finalDateService = ""
      this.isaverbacaoService = false
      this.daysService = ""
      this.isfaltasService = false
      this.islicencasService = false
      this.issuspensoesService = false
      this.row = null

      // Valores da tabela
      this.service.yearlyInfo = []

      //Limpar valores do periodo abrangido no pdf
      this.service.allPeriod.start = ""
      this.service.allPeriod.final = ""

    },
    toDate(string) {
      if (string == "inicial") {
        this.inicialDate = new Date(this.inicialDateService);
      } else if (string == "final") {
        this.finalDate = new Date(this.finalDateService);
      }

      // Preenche a data final se estiver vazia
      if (this.inicialDateService !== "" && this.finalDateService === "") {
        this.finalDate = this.inicialDate;
        this.finalDateService = this.inicialDateService;
      }

      // Verifica se a data inicial é maior que a final
      if (this.inicialDateService && this.finalDateService) {
        this.daysService = (this.finalDate - this.inicialDate) / 86400000 + 1;
        this.validationInicial = this.daysService > 0 || "Data inicial maior que data final.";
        this.validationFinal = this.daysService > 0 || "Data final menor que data inicial.";
      }
    },
    choose() {
      this.isaverbacaoService = "averbacao" == this.row
      this.isfaltasService = "faltas" == this.row
      this.islicencasService = "licencas" == this.row
      this.issuspensoesService = "suspensoes" == this.row
    }
  }
}
</script>

<style>
/* 
  TESTE
 */
</style>