<template>
    <v-col cols="auto" class="mt-2">
      <v-container class="pa-0">
        <v-row>
          <v-col cols="auto" class="pa-0" style="align-self: center; background-color: #e0e0e0; border-radius: 4px">
            <v-icon class="pa-2">{{ icon }}</v-icon>
          </v-col>
          <v-col class="mx-2" style="align-self: center">
            <v-row>
              <span style="font-size: 13px; color: rgb(115, 114, 114)">{{ title }}</span>
            </v-row>
            <v-row>
              <span style="font-size: 13px">{{ value }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      value: String,
      icon: String
    }
  }
  </script>
  
  <style></style>