<template>
    <v-avatar color="brown" size="42" v-if="icon">
        <span class="white--text text-h5">{{ user.initials }}</span>
    </v-avatar>
    <v-menu :close-on-content-click="false" min-width="200px" rounded offset-y v-else>
        <template v-slot:activator="{ on }">
            <v-btn icon large v-on="on">
                <v-avatar color="cyan" size="42">
                    <span class="white--text text-h5">{{ user.initials }}</span>
                </v-avatar>
            </v-btn>
        </template>
        <v-card>
            <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                    <v-avatar color="cyan">
                        <span class="white--text text-h5">{{ user.initials }}</span>
                    </v-avatar>
                    <h3>{{ user.name }}</h3>
                    <p class="text-caption mt-1">
                        {{ user.email }}
                    </p>
                    <p class="text-caption mt-1">
                        {{ user.isEmailVerified ? 'Email verificado' : 'Email não verificado' }}
                    </p>
                    <v-divider v-if="!user.isEmailVerified" class="my-3"></v-divider>
                   
                    <v-btn v-if="!user.isEmailVerified" :disabled="confirmationSent" @click="sendEmail()" depressed
                        rounded text>
                        Verificar email
                    </v-btn> 
                    <span v-if="sent">  <v-progress-circular class="ml-2" :size="20" :width="7" color="blue" indeterminate>
                    </v-progress-circular></span>
                    <span v-if="error"> <v-icon color="error">mdi-warning</v-icon></span>
                
                    <v-divider class="my-3"></v-divider>
                    <v-btn depressed rounded text @click="logout()">
                        Sair
                    </v-btn>
                </div>
            </v-list-item-content>
        </v-card>
    </v-menu>
</template>

<script>
import axios from 'axios'

const urlbackend = process.env.VUE_APP_API_URL

export default {
    data: () => ({
        verify: false,
        user: {
        },
        confirmationSent: false,
        sent: false,
        error: false,

    }),
    props: {
        icon: Boolean
    },
    computed: {

    },
    mounted() {
    },
    created() {
        // Fetch user data from API
        this.user = JSON.parse(localStorage.getItem('user')).user
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...this.user.name.matchAll(rgx)] || [];

        this.user.initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();


    },

    methods: {
        sendEmail() {
            this.sent=true
            if (this.user.isEmailVerified === false) {
                axios.post(urlbackend + "/auth/send-verification-email").then((res) => {
                    if (res.status >= 200 && res.status < 300) {
                        setTimeout(() => {
                            window.alert("Token enviado para o email : " + this.user.email + " , verifique sua caixa de entrada.");
                            this.confirmationSent = true;
                            this.sent = false;
                        }, 1000);

                    }

                }).catch((err) => {
                    window.alert("Não foi possível enviar o email de verificação, tente novamente.");
                    this.confirmationSent = false;
                    this.error=true
                    console.log(err)
                })
            }
        },

        logout() {
            localStorage.removeItem('user')
            this.$router.push('/login')
        }
    }
} 
</script>

<style></style>