<template>
  <v-container fluid class="mt-3 py-0">
    <v-textarea name="input-7-1" filled label="OBS:" auto-grow v-model="observations" @change="service.observations = observations"></v-textarea>
  </v-container>
</template>

<script>

//import TimeCounterVue from '../views/TimeCounter.vue';
//const timeElapsed = Date.now()
//const today = new Date(timeElapsed)
//import moment from 'moment';


export default {
  props: {
    service: Object
  },
  data() {
    return {
      observations: ""
    }
  },
  created() {
  },
  computed: {
    faultsTotal() {
      var sum = 0
      this.service.yearlyInfo.forEach(element => {
        sum = sum + element.faults
      })
      return sum
    },
    licensesTotal() {
      var sum = 0
      this.service.yearlyInfo.forEach(element => {
        sum = sum + element.licenses
      })
      return sum
    },
    timePublicTotal() {
      var sum = 0
      var time = []
      this.service.yearlyInfo.forEach(element => {
        sum = sum + element.allTime
      })
      time[0] = parseInt(sum / 365)
      time[1] = parseInt(((sum - time[0] * 365) % 365) / 30)
      time[2] = (sum % 365) % 30
      time[3] = sum
      return time;
    },
    timePrivateTotal() {
      var sum = 0
      var time = []
      this.service.yearlyInfo.forEach(element => {
        sum = sum + element.averbacao
      })
      time[0] = parseInt(sum / 365)
      time[1] = parseInt(((sum - time[0] * 365) % 365) / 30)
      time[2] = (sum % 365) % 30
      time[3] = sum
      return time;
    },
    timeTotal() {
      var sum = 0
      var time = []
      this.service.yearlyInfo.forEach(element => {
        sum = sum + element.averbacao + element.allTime - element.faults - element.licenses - element.suspensions
      })
      time[0] = parseInt(sum / 365)
      time[1] = parseInt(((sum - time[0] * 365) % 365) / 30)
      time[2] = (sum % 365) % 30
      time[3] = sum
      return time;
    },

    trabdaysTotal(){
       var time = []  
       time[0] = parseInt(this.service.trabdays / 365)
       time[1] = parseInt(((this.service.trabdays - time[0] * 365) % 365) / 30)
       time[2] = (this.service.trabdays % 365) % 30
       time[3] = this.service.trabdays
       return time;
       
    }

  },
  methods: {

    update(service) {
      this.service = service
      this.observations = `            ${this.timePublicTotal[3]} Dias de contribuição, ou seja ${this.timePublicTotal[0]} anos, ${this.timePublicTotal[1]} meses e ${this.timePublicTotal[2]} dias.\n\
            \n\
            ${this.timePrivateTotal[3]} Dias averbados, ou seja ${this.timePrivateTotal[0]} anos, ${this.timePrivateTotal[1]} meses e ${this.timePrivateTotal[2]} dias.\n\
            \n\
            Licenças em ${this.licensesTotal} dias\n\
            \n\
            Faltas não justificadas em  ${this.faultsTotal} dias.\n\
            \n\
            ${this.timeTotal[3]} Dias, ou seja ${this.timeTotal[0]} anos, ${this.timeTotal[1]} meses e ${this.timeTotal[2]} dias de tempo de contribuição com ${this.service.userAge} anos de idade.\n\
            \n\
            O servidor contava com ${this.trabdaysTotal[3]} Dias, ou seja ${this.trabdaysTotal[0]} anos, ${this.trabdaysTotal[1]} meses e ${this.trabdaysTotal[2]} dias de tempo de contribuição até o dia 18/03/2020, um dia antes da entrada em vigor da EC n. 82/2019, com  ${this.getAge()} anos de idade.
            \n\
            Data da ultima contagem ${this.service.emissao}.`


      this.service.observations = this.observations
    },
    getAge(){
      const diff = (new Date()).getFullYear() - 2020
      return this.service.userAge - diff
    }
  }

}
</script>

<style>

</style>