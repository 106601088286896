<template>
  <v-container :class=" $vuetify.breakpoint.xsOnly? 'fluid' :'fixed-component'" class="pa-4">
      <v-row>
        <v-col class="pa-0 pb-3">
          <v-card :color="updateColor">
            <v-card-title style="justify-content: space-between;">
              <span>
                <span v-if="isPrescripted">Crédito prescrito em <strong>{{ userPrescriptionDate }}</strong></span>
                <span v-else-if="unificado">Processo <strong>unificado</strong></span>
                <span v-else-if="userPrescriptionDate != 'Invalid date'" style="font-size: 1.2rem">Crédito prescreverá em <strong>{{ userPrescriptionDate }}</strong></span>
                <span v-else>Processo <strong>em análise</strong></span>
              </span>
              <v-spacer></v-spacer>
              <v-tooltip left :color="process.validated? 'green':'orange'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="process.validated? 'green':'orange'" v-bind="attrs" v-on="on">{{process.validated? "mdi-check-circle-outline":"mdi-alert-circle-outline"}}</v-icon>
                </template>
                <span v-if="process.validated">Validado</span>
                <span v-else>Não validado</span>
              </v-tooltip>
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon text><v-icon>mdi-dots-vertical</v-icon></v-btn>
                </template>
                  <v-list>
                    <v-list-item v-if="user && user.user.role == 'adm'" @click="$emit('updateViewMode', 'clean')">Modo Limpo</v-list-item>
                    <v-list-item @click="dialog = true">Lembre-me</v-list-item>
                    <v-dialog v-model="dialog" max-width="500" transition="dialog-bottom-transition">
                      <custom-notification :dialog.sync="dialog" :number-process="treatedProcess.numberProcess"></custom-notification>
                    </v-dialog>
                  </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text>
              <span v-if="process.validated"><strong>Processo Validado</strong>.</span>
              <span> Prescrição calculada a partir da definição da <strong>{{ entendimento }}</strong>.</span>
              <br>
              <span>Última atualização: <strong>{{ formatUpdateDate(process.updatedAt) }}</strong>.</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="justify-content: space-between">
        <process-atribute-main title="N° Processo:" :value="treatedProcess.numberProcess"
          icon="mdi-file"></process-atribute-main>
        <process-atribute-main title="Valor da execução:"
          :value="new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(treatedProcess.debtValue)"
          icon="mdi-cash-multiple"></process-atribute-main>
        <process-atribute-main title="Assunto:" :value="treatedProcess.area"
          icon="mdi-scale-balance"></process-atribute-main>
      </v-row>
      <v-row style="justify-content: space-between" v-if="maisDetalhes == true" class="pt-2 pr-2">
        <process-atribute title="Tribunal:" :value="treatedProcess.judge"></process-atribute>
        <!-- <process-atribute title="Apensado ao:"
          :value="treatedProcess.attachedTo ? treatedProcess.attachedTo.numberProcess : ''">
        </process-atribute> -->
        <process-atribute title="Foro:" :value="treatedProcess.subject"></process-atribute>
        <process-atribute title="Controle:" :value="treatedProcess.control"></process-atribute>
        <process-atribute title="Distribuição:" :value="treatedProcess.distribution"></process-atribute>
        <process-atribute title="Executado:" :value="treatedProcess.defendant"></process-atribute>
        <process-atribute title="Área:" :value="treatedProcess.area"></process-atribute>
        <process-atribute title="Vara:" :value="treatedProcess.court"></process-atribute>
      </v-row>
      <v-row style="justify-content: unset; text-decoration: underline">
        <v-btn text x-small color="primary" @click="maisDetalhesExibir" class="mt-3">
          {{ maisDetalhes ? 'Menos detalhes' : 'Mais detalhes' }}
        </v-btn>
      </v-row>
      <v-row cols="4" class="pt-3">
        <v-col cols="4" class="pl-0 pr-3">

          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on }">
              <v-btn outlined v-on="on">Apensados</v-btn>
            </template>
            <v-card>
              <v-data-table dense :headers="headers" :items="apensadosArray" :mobile-breakpoint="0">
                <template v-slot:item="{ item }">
                  <tr>
                    <td @click="redirect(item)"><v-progress-circular v-if="item.inScrapping" :size="20" indeterminate
                        color="primary"></v-progress-circular>
                      {{ item.numberProcess }}
                    </td>
                    <td @click="redirect(item)">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-chip v-if="item.inScrapping">
                            {{ '--/--/----' }}
                          </v-chip>
                          <v-chip v-else outlined :color="item.color" v-on="on">
                            {{ new Date(item.prescriptionDate).toLocaleDateString('pt-BR') }}
                          </v-chip>
                        </template>
                        <span>{{ item.colorTooltip }}</span>
                      </v-tooltip>
                    </td>
                    <td @click="redirect(item)">
                      <div v-if="item.inScrapping">{{ '-----------------' }}</div>
                      <div v-else>
                        {{ item.unified ? "unificado" : item.attachedTo != null ? "Apenso" : item.attached != null ?
                        "Apensado" :
                        "" }}
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-menu>
        </v-col>
        <v-col v-if="!process.validated" cols="12" md="8" class="px-0">
          <v-btn outlined @click="setValidated()">Validar data da prescrição</v-btn>
        </v-col>
        </v-row>
        <v-row v-if="process.validated" class="py-2">
          <v-col cols="12">
          <document-editor :process="process"></document-editor>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-alert dense type="info" v-if="!process.validated" class="mt-5">O documento é exibido após <strong>validar a
              data de
              prescrição</strong>.</v-alert>
        </v-col>

  </v-container>
</template>

<script>
import moment from 'moment'
import ProcessAtributeMain from "./ProcessAtributeMain.vue"
import ProcessAtribute from "./ProcessAtribute.vue"
import DocumentEditor from "./DocumentEditor.vue"
import formatter from "../utils/formatter"
import CustomNotification from "./CustomNotification.vue"

export default {
  components: {
    ProcessAtribute,
    ProcessAtributeMain,
    DocumentEditor,
    CustomNotification,
  },
  props: {
    process: {
      type: Object,
      required: true
    },
    unificado: {
      type: Boolean
    },
    userPrescriptionDate: {
      type: String
    }
  },
  data: () => ({
    dialog: false,
    maisDetalhes: false,
    treatedProcess: {},
    appbarHeight: "0px",
    headers: [
      { text: 'PROCESSO', sortable: false, },
      { text: 'PRESCRIÇÃO', sortable: false, },
      { text: 'APENSOS', sortable: false, },
    ],
    apensadosArray: [],
    colorTooltip: '',
    prescrito: false, // Variavel da lógica para ser exibida no card
    user: null
  }),
  computed: {
    updateColorIcon() {
      var hoje = new Date()
      var dateProcess = new Date(this.process.updatedAt)
      if (moment(dateProcess).isBefore(hoje, 'day'))
        return "orange"
      return "green"
    },
    updateColor() {
      var hoje = new Date()
      if (moment(this.userPrescriptionDate, "DD/MM/YYYY").isBefore(hoje, 'day'))
        return  "#faf2cb"
      return "#b7e4c7"
    },
    isPrescripted() {
      return moment(this.userPrescriptionDate, "DD/MM/YYYY").isBefore(new Date(), 'day')
    },

    entendimento() {
      return moment(this.process.distributionYear, "DD/MM/YYYY").subtract(moment("19/06/2005", "DD/MM/YYYY")) < 0 ?  'RESP' : 'LEF'
    }
  },
  watch: {
    process: {
      handler() {
        this.apensadosArray = []
        if (this.process.attachedTo)
          this.apensadosArray.push(this.process.attachedTo)
        if (this.process.attached)
          this.apensadosArray.push(...this.process.attached)
        this.apensadosArray.map((item) => {
          item.numberProcess = formatter.processNumberFormat(item.numberProcess)
          item.color = this.changeColor(item)
          item.colorTooltip = this.colorTooltip
          if (item.unified) {
            item.prescriptionDate = '--/--/--'
          }
          return item
        })
      },
      deep: true
    }
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.treatedProcess = { ...this.process }
    this.treatedProcess.numberProcess = formatter.processNumberFormat(this.treatedProcess.numberProcess)
    this.treatedProcess.defendant = this.treatedProcess.defendant.join(", ")
    this.appbarHeight = document.getElementById("appbar").style.height
    if (this.process.attachedTo)
      this.apensadosArray.push(this.process.attachedTo)
    if (this.process.attached)
      this.apensadosArray.push(...this.process.attached)
    this.apensadosArray.map((item) => {
      item.numberProcess = formatter.processNumberFormat(item.numberProcess)
      item.color = this.changeColor(item)
      item.colorTooltip = this.colorTooltip
      if (item.unified) {
        item.prescriptionDate = '--/--/--'
      }
      return item
    })
    //this.calculatePrescriptionStatus()
  },
  methods: {
    formatUpdateDate(date) {
      return new Date(date).toLocaleDateString('pt-BR')
    },
    changeColor(item) {
      var dataPrescricao = new Date(item.prescriptionDate)
      var hoje = new Date()
      var seteDias = new Date()
      seteDias.setDate(hoje.getDate() + 7)
      if (dataPrescricao > hoje) {
        if (dataPrescricao < seteDias) {
          this.colorTooltip = "Próximo a Prescrição"
          return "primary"
        } else {
          this.colorTooltip = "Em andamento"
          return "green lighten-1"
        }
      } else if (dataPrescricao < hoje) {
        this.colorTooltip = "Prescrito"
        return "amber lighten-1"
      }
    },
    maisDetalhesExibir() {
      this.maisDetalhes = !this.maisDetalhes
    },
    redirect(item) {
      var nprocess
      nprocess = item.numberProcess.replace(/\D/g, '')
      if (item.debtValue) {
        this.$router.push({ path: '/process/' + nprocess })
        location.reload()
      }
    },
    setValidated() {
      this.$emit('updateValidate', true)
    },
    calculatePrescriptionStatus() {
      var hoje = new Date()
      var prescricao = new Date(this.process.prescriptionDate)
      this.prescrito = moment(prescricao).isBefore(hoje, 'day')
    },
    updateDialog(value) {
      this.dialog = value
    }
  }
}
</script>

<style>
.fixed-component {
  position: fixed;
  top: 100;
  right: 90;
  width: 40%;
  height: 100%;
  overflow-y: auto;
}
</style>
