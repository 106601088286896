<template>
    <Chati></Chati>
</template>
<script>
import Chati from '@/components/Chati'
export default {
    components: {
        Chati
    },
}
</script>