<template>
  <v-card width="100%" class="justify-center elevation-1 pb-4 pt-3" color="#f5f5f5">
    <v-container fluid class="py-0" style="overflow: hidden">
      <v-row :class="['scroll', { 'justify-center': scrollCenter }]" ref="Scroll">
        <v-col v-for="(n, index) of summarizedMovements" :key="index">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-container class="pa-0 ma-2">
                <v-row class="pr-4 pt-2">
                  <span>{{ n.dateMovement }}</span>
                </v-row>
                <v-row class="pb-1">
                  <v-btn fab depressed small @click="scrolltoItem(n)" v-bind="attrs" v-on="on" :color="n.color"
                    style="align-self: center;">
                    <v-icon>{{ n.icon }}</v-icon>
                    <div v-if="!n.id">
                      <v-snackbar v-model="snackbar" :timeout="5000"> {{ text.toLowerCase() }} </v-snackbar>
                    </div>
                  </v-btn>
                  <v-divider style="align-self: center;" v-if="index < summarizedMovements.length - 1"></v-divider>
                </v-row>
              </v-container>
            </template>
            <span>{{ n.tooltip }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    itensProp: {
      type: Array,
      required: true
    },
    classes: {
      type: Array,
      required: true
    },
    subclasses: {
      type: Array,
      required: true
    },
    process: Object,
    distributionYear: String
  },
  data() {
    return {
      snackbar: false,
      text: 'Este item não pertence a timeline',
      scrollCenter: false,
      windowHeight: window.innerHeight,
      unified: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToEnd();
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  watch:{
      unified(unificado){
        this.$emit('updateUnified', unificado)
          
      }
  },

  methods: {
    getClassNameById(id) {
      let foundClass = this.subclasses.find((el) => el.id == id)
      if (!foundClass) {
        foundClass = this.classes.find((el) => el.id == id)
      }
      return foundClass ? foundClass.name : ''
    },
    scrolltoItem(n) {
      if (!n.id)
        this.snackbar = true
      else {
        const element = document.getElementById(n.id);
        element.scrollIntoView({ behavior: "smooth", block: "center" });
        let a = element.getElementsByClassName("v-card")[0]
        var modifiedColor = n.color.substring(0, 7) + "40"
        a.style.backgroundColor = modifiedColor
        var delayInMilliseconds = 2000; //1 second

        setTimeout(function () {
          //your code to be executed after 1 second
          a.style.backgroundColor = "#FFFFFF"
        }, delayInMilliseconds);
      }

    },

    scrollToEnd() {
      const container = this.$refs.Scroll;
      container.scrollLeft = container.scrollWidth - container.clientWidth;
      if (container.scrollLeft == 0)
        this.scrollCenter = true
    },

    onResize() {
      this.scrollCenter = false
      this.windowHeight = window.innerHeight
      this.scrollToEnd()
    },
    getPrescriptionDate() {
      var entendimento = moment(this.process.distributionYear, "DD/MM/YYYY").subtract(moment("19/06/2005", "DD/MM/YYYY")) < 0 ?  'RESP' : 'LEF'
      if(entendimento == 'LEF')
      return  moment(this.startcount, "DD/MM/YYYY")
              .add(6, 'years')  // Adicionando 6 anos
              .format('DD/MM/YYYY')
      else  
       return moment(this.startcount, "DD/MM/YYYY")
              .add(6, 'years')  // Adicionando 5 anos se não houver suspensão, 6 anos se houver
              .format('DD/MM/YYYY')
    },
  },
  computed: {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    summarizedMovements() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let filteredItens = this.itensProp.filter((el) => el.prescriptionMovement || el.feedback.length > 0) 
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.lastPenhoraIndex = -1
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.startcount = filteredItens[filteredItens.length - 1] ? filteredItens[filteredItens.length - 1].dateMovement : null
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.suspended = 0
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.unified = false
      //console.log(moment(this.startcount, "DD/MM/YYYY").fromNow())

      filteredItens.sort((a, b) => moment(a.dateMovement, "DD/MM/YYYY").subtract(moment(b.dateMovement, "DD/MM/YYYY")))

      filteredItens = filteredItens.map((el, i) => {
        let subclasse = ""
        let classe = ""
        if (el.feedback.length > 0) { 
          subclasse = el.feedback[el.feedback.length - 1].subClass
          classe = el.feedback[el.feedback.length - 1].class 
        } else {
          subclasse = el.statusTemporary[el.statusTemporary.length - 1].class.id
          classe = Object.prototype.hasOwnProperty.call(el.statusTemporary[el.statusTemporary.length - 1].class, 'relatesTo') ? el.statusTemporary[el.statusTemporary.length - 1].class.relatesTo : subclasse
        }

        // TERMO INICIAL
        if (classe == '651f0944695db90007143670') {
          el.icon = "mdi-play"
          el.tooltip = "Termo Inicial"
          el.year = "ano 0"
          this.startcount = el.dateMovement
          this.suspended = 0
        }
        // TERMO FINAL
        else if (classe == '646b8c9972e7235fe7e7b53a') {
          el.icon = "mdi-gavel"
          el.tooltip = "Sentença"
        }
        // TERMO SUSPENSIVO
        else if (classe == '651f084c695db9000714366e') {
          el.icon = "mdi-pause-circle"
          el.tooltip = "Termo Suspensivo"
          this.suspended = 1
        }
        // TERMO INTERRUPTIVO
        else if (classe == '651f0857695db9000714366f') {
          el.icon = "mdi-history"
          el.tooltip = "Termo Interruptivo"
          this.lastPenhoraIndex = i
          this.startcount = el.dateMovement
          this.suspended = 0
        }
        // Unificação - Suspensão indefinida
        else if (classe == '6509dbedb9924a000753c5f3') {
          el.icon = "mdi-alert-octagon"
          el.tooltip = "Unificação do Processo"
          this.unified = true
        }
        else {
          return null
        }
        return el
      }, this)
        

      // Lógica que define deixar todas movimentações amarelas antes da ultima penhora
      filteredItens = filteredItens.map((el, i) => {
        if(el) {
          el.color = '#81C784'
          if (i < this.lastPenhoraIndex || el.icon == "mdi-alert-octagon") {
            el.color = '#FFD54F'
          }
        }
        return el
      }).filter(el => el !== null); // Filtra os elementos nulos (itens "não influencia")

      // Exclui a movimentação da predição quando não há classificados no array (devido a feedback)
      if (filteredItens.length >= 1 && !this.unified) {
        const newItems = filteredItens.map((el) => {
          return {
            ...el
          }
        })

        // Verificar se o processo é anterior a 2005 (ano 2005)
        const distributionDate = this.process.distribution.split(" ")[0] // extrai apenas a parte da data
        const parts = distributionDate.split("/") // divide a data em partes usando "/" 
        const distributionDay = parseInt(parts[0]) // extrai o dia
        const distributionMonth = parseInt(parts[1]) // extrai o mês
        const distributionYear = parseInt(parts[2]) // extrai o ano

        const dayThreshold = 19
        const monthThreshold = 6
        const yearThreshold = 2005
        if ( // Verifica por dia mes e ano se eh menor que 21/06/2005
          distributionYear < yearThreshold ||
          (distributionYear === yearThreshold && distributionMonth < monthThreshold) ||
          (distributionYear === yearThreshold && distributionMonth === monthThreshold && distributionDay < dayThreshold)
        ) {
          newItems.push({
            icon: "mdi-alert-octagon",
            tooltip: "Possível data da prescrição (LEF)",
            color: '#EF9A9A',
            // Calcular a data de prescrição usando LEF (6 anos após a última suspensão)
            dateMovement: moment(this.startcount, "DD/MM/YYYY")
              .add(6, 'years')  // Adicionando 6 anos
              .format('DD/MM/YYYY')
          })
        } else {
          // Calculando a prescrição usando a lógica anterior (RESP)
          newItems.push({
            icon: "mdi-alert-octagon",
            tooltip: "Possível data da prescrição (RESP)",
            color: '#EF9A9A',
            // Calcular a data de prescrição usando a lógica anterior (RESP)
            dateMovement: moment(this.startcount, "DD/MM/YYYY")
              .add(6, 'years')  // Adicionando 5 anos se não houver suspensão, 6 anos se houver
              .format('DD/MM/YYYY')
          })
        }

        filteredItens = newItems
      }
      filteredItens.sort((a, b) => moment(a.dateMovement, "DD/MM/YYYY").subtract(moment(b.dateMovement, "DD/MM/YYYY")))

      this.$emit('updatePrescriptionDate', this.getPrescriptionDate())
      return filteredItens
    }
  },
}
</script>

<style>
.scroll {
  overflow-x: auto;
  flex-wrap: nowrap;
}
</style>
