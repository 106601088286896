<template>
  <v-container class="pa-12">
    <v-row justify="center">
      <!--Service Period-->
      <service-period
        ref="period"
        @add-period="addPeriod"
        :service="serviceInfos"
      ></service-period>
    </v-row>
    <v-row class="py-5">
      <v-alert type="error" width="100%" v-if="showError">
      {{
        errorMsg
      }}</v-alert>
    </v-row>
    <v-row justify="center">
      <!--Period Table-->
      <period-table @update-observation="updateObservation" :data="serviceInfos"
      ></period-table>
    </v-row>
    <v-row justify="center">
      <!--Period Observations-->
      <period-observations ref="observation" :service="serviceInfos"
      ></period-observations>
    </v-row>
    <v-row class="mt-0 mb-2 py-0">
        <v-container>
          <v-row>
            <v-col>
              <v-select hide-details item-text="name"  label="Elaborado por" filled
                :items="
                  servidores.filter((servidor) => {
                    return servidor.department == 'COPGE/UGP/DAP';
                  })
                ">
              </v-select>
            </v-col>
            <v-col>
              <v-text-field hide-details v-model.lazy="serviceInfos.emissao" label="Data de emissão" filled placeholder="dd/mm/yyyy" v-mask="'##/##/####'" @change="updateObservation()"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
    </v-row>
    <v-row justify="center">
      <!--Service PDF-->
      <service-pdf :service="serviceInfos"></service-pdf>
    </v-row>
  </v-container>
</template>

<script>
import ServicePeriod from "../components/ServicePeriod.vue";
import PeriodTable from "../components/PeriodTable.vue";
import PeriodObservations from "../components/PeriodObservations.vue";
import ServicePdf from "../components/ServicePDF.vue";
import servidores from "../assets/servidores_pge.json";
import moment from 'moment';
import axios from 'axios';
const urlbackend = process.env.VUE_APP_API_URL;
//import ServiceInformation from '../components/ServiceInformation.vue';

export default {
  components: {
    ServicePeriod,
    PeriodTable,
    PeriodObservations,
    ServicePdf,
    //ServiceInformation
  },
  props: {
    person: {
      type: String,
    },
  },
  data: () => ({
    showError: false,
    errorMsg: "",
    servidores: servidores.servidores,
    serviceInfos: {
      allPeriod: {},
      nameService: "",
      emissao: "",
      userProcess: "",
      userRegistration: "",
      userBirthDate: "",
      userAge: "",
      userClass: "",
      userCode: "",
      userBoard: "",
      userCareer: "",
      userFunction: "",
      userCapacity: "",
      observations: "",
      elabored: "",
      trabdays: 0,
      yearlyInfo: [
        /*{
          year: 2010,
          allTime: 310, // mudar para timestamp do início e do fim
          averbacao: 0,
          faults: 10,
          licenses: 5,
          suspensions: 2,
          total:null
        } */
      ],
    },
  }),
  created() {
    let servidor = JSON.parse(this.person);
    
    axios.get(urlbackend+`/rh/person/${servidor}`).then((res) => {
      if(res.status == 200 && res.data) {
        servidor = res.data
          // this.serviceInfos.careers.sort((a, b) => {
          //   return a.id - b.id;
          // });
          // Conversão para objeto Date e formatação
          servidor.birthday = new Date(servidor.birthday); // Cria um objeto Date
          this.serviceInfos.userBirthDate = this.formatarDataParaDDMMAAAA(servidor.birthday); // Formata a data
          this.serviceInfos.nameService = servidor.name
          this.serviceInfos.userCareer = servidor.careers[servidor.careers.length-1].post
          this.serviceInfos.userFunction = servidor.careers[servidor.careers.length-1].role
          this.serviceInfos.userBoard = servidor.careers[servidor.careers.length-1].bond
          this.serviceInfos.userRegistration = servidor.careers[servidor.careers.length-1].jobRegister
          this.serviceInfos.userCapacity = servidor.careers[servidor.careers.length-1].department
          //servidor.entryDate = this.formatarDataParaDDMMAAAA(servidor.entryDate);
      }
    });
  },
  methods: {
    // não está sendo usado ainda
    formatarDataParaDDMMAAAA(data) {
    const dia = String(data.getDate() + 1).padStart(2, '0'); // Obtém o dia com dois dígitos (com zero à esquerda, se necessário) e ADICIONA 1 para ajustar a data devido ao fuso-horário
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Obtém o mês (vale ressaltar que os meses em JavaScript começam do zero, por isso o +1)
    const ano = data.getFullYear(); // Obtém o ano com quatro dígitos

    return `${dia}/${mes}/${ano}`;
  },
    addPeriod(start, final, averbacao, faltas, licencas, suspensoes) {
      var now = new Date();
      var offset = now.getTimezoneOffset();
      var date1 = '2020-03-18';

      if(moment(final).isBefore('2020-03-18'))
         date1 = final
      
      //Verifica dias trabalhados até o dia 18 de marco de 2020
      if((moment(start).isBefore('2020-03-18') || moment(date1).isBetween(start, final, undefined, "[]")) && !(faltas||licencas||suspensoes)){
        if(moment(start).isBefore('2020-03-18')){
          const diffInMs   = new Date(date1) - new Date(start) 
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
          this.serviceInfos.trabdays += diffInDays + 1
        }else{
          const diffInMs   = new Date(final) - new Date(date1) 
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
          this.serviceInfos.trabdays += diffInDays + 1
        }
      }

      //Verifica faltas, licencas ou suspensoes até o dia 18 de marco de 2020
      if((moment(start).isBefore('2020-03-18') || moment(date1).isBetween(start, final, undefined, "[]")) && (faltas||licencas||suspensoes)){
        if(moment(start).isBefore('2020-03-18')){
          const diffInMs   = new Date(date1) - new Date(start) 
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
          this.serviceInfos.trabdays -= diffInDays + 1
        }else{
          const diffInMs   = new Date(final) - new Date(date1)
          const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
          this.serviceInfos.trabdays -= diffInDays + 1
        } 
      }

      //Atualiza o periodo abrangido no pdf
      if ((start < this.serviceInfos.allPeriod.start || !this.serviceInfos.allPeriod.start)) {
        this.serviceInfos.allPeriod.start = new Date(start);
      }
      if ((final > this.serviceInfos.allPeriod.final || !this.serviceInfos.allPeriod.final)) {
        this.serviceInfos.allPeriod.final = new Date(final);
      }

      let totalSec = new Date(final) - new Date(start);
      let totalDays = totalSec / 86400000;

      var date = new Date(new Date(start).getTime() + offset * 60000);
      var nextYear = new Date(
        new Date(date.getFullYear() + 1, 0, 1, 0) + offset * 60000
      );
      var end = new Date(new Date(final).getTime() + offset * 60000);

      var yearlyInfo = {};
      while (totalDays >= 0) {
        let days = 0;

        if (nextYear < end) {
          days = (nextYear - date) / 86400000;
        } else {
          days = (end - date) / 86400000 + 1;
        }

        var year = date.getFullYear();
        totalDays = totalDays - days;

        yearlyInfo[year] = Math.round(days);

        date = nextYear;
        nextYear = new Date(
          new Date(date.getFullYear() + 1, 0, 1, 0) + offset * 60000
        );
      }

      let serviceInfos = JSON.parse(JSON.stringify(this.serviceInfos));

      serviceInfos.yearlyInfo.map((el) => {
        if (yearlyInfo[el.year]) {
          //Quando for tempo de averbacao
          if (averbacao) {
            el.averbacao += yearlyInfo[el.year];
          }
          //Quando for tempo de falta
          else if (faltas) {
            el.faults += yearlyInfo[el.year];
          }
          //Quando for tempo de licenca
          else if (licencas) {
            el.licenses += yearlyInfo[el.year];
          }
          //Quando for tempo de suspensao
          else if (suspensoes) {
            el.suspensions += yearlyInfo[el.year];
          }
          //Quando for tempo bruto
          else {
            el.allTime += yearlyInfo[el.year];
          }
          el.total =
            el.allTime +
            el.averbacao -
            el.faults -
            el.suspensions -
            el.licenses;
          delete yearlyInfo[el.year];
        }
      });

      for (const year in yearlyInfo) {
        if (faltas) {
          serviceInfos.yearlyInfo.push({
            year: year,
            averbacao: 0,
            allTime: 0,
            faults: yearlyInfo[year],
            licenses: 0,
            suspensions: 0,
            total: -yearlyInfo[year],
          });
        } else if (licencas) {
          serviceInfos.yearlyInfo.push({
            year: year,
            averbacao: 0,
            allTime: 0,
            faults: 0,
            licenses: yearlyInfo[year],
            suspensions: 0,
            total: -yearlyInfo[year],
          });
        } else if (suspensoes) {
          serviceInfos.yearlyInfo.push({
            year: year,
            averbacao: 0,
            allTime: 0,
            faults: 0,
            licenses: 0,
            suspensions: yearlyInfo[year],
            total: -yearlyInfo[year],
          });
        } else if (averbacao) {
          serviceInfos.yearlyInfo.push({
            year: year,
            averbacao: yearlyInfo[year],
            allTime: 0,
            faults: 0,
            licenses: 0,
            suspensions: 0,
            total: yearlyInfo[year],
          });
        } else {
          serviceInfos.yearlyInfo.push({
            year: year,
            allTime: yearlyInfo[year],
            averbacao: 0,
            faults: 0,
            licenses: 0,
            suspensions: 0,
            total: yearlyInfo[year],
          });
        }
      }

      const yearsWithErrors = serviceInfos.yearlyInfo.filter(
        (el) => el.total > this.daysInYear(el.year)
      );

      if (yearsWithErrors.length > 0) {
        this.showError = true;
        this.errorMsg =
          "O período selecionado ultrapassa a quantidade de dias no(s) seguinte(s) ano(s): " +
          yearsWithErrors.map((elem) => elem.year).join(", ");
      } else {
        serviceInfos.yearlyInfo.sort((a, b) => {
          return a.year - b.year;
        });
        this.serviceInfos = serviceInfos;
        this.showError = false;
        this.$refs.period.clear();
        this.updateObservation();
      }
    },
    updateObservation() {
      this.$refs.observation.update(this.serviceInfos);
    },
    daysInYear(year) {
      return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
    },
    /*
    formatarData() {
      let data = this.service.emissao
      this.service.emissao = moment(data).format("DD/MM/YYYY")
      return data
    }
    */
  },
};
</script>

<style>
</style>