<template>
  <v-container fluid fill-height>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card>
          <login-frame></login-frame>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginFrame from '../components/LoginFrame.vue';

export default ({
  components: {
    LoginFrame
  },
})
</script>
