<template>
  <v-container>
    <v-row>
      <v-col align-content-center cols="12" md="6" lg="6" class="pl-0" style="align-self: center;">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="150" max-width="300" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-row class="mt-2">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
              <v-text-field label="Filtrar" append-icon="mdi-magnify" solo dense v-model="textfilter">
              </v-text-field>
            </v-row>
          </template>
          <v-card max-width="300" class="pa-2">
            <v-card-title class="pb-0">
              Filtro por categorias
              <v-spacer></v-spacer>
              <v-btn icon large color="error" @click="menu = false"><v-icon
                  color="error">mdi-close-circle-outline</v-icon></v-btn>
            </v-card-title>
            <v-list-item class="pt-0">
              <v-radio-group v-model="selectedFilter" @change="applyFilters">
                <v-radio dense label="Todos" value="all"></v-radio>
                <v-radio dense label="Ativos" value="active"></v-radio>
                <v-radio dense label="Exonerados" value="exonerated"></v-radio>
              </v-radio-group>
            </v-list-item>
          </v-card>
        </v-menu>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" lg="6" style="text-align: end;" class="pr-0">
        <!-- BOTÃO PARA EXPORTAR DADOS EM EXCEL -->
        <v-btn color="teal" dark class="mx-2 my-2" @click="exportToExcel">Exportar dados em Excel</v-btn>
        <!-- BOTÃO PARA ADICIONAR NOVO SERVIDOR -->
        <v-btn color="primary" dark @click="novoServidor()">Novo Servidor</v-btn>
      </v-col>
    </v-row>
    <v-row class="py-6">
      <v-chip outlined class="mb-2">
        Filtrando por {{ currentFilterLabel }}
      </v-chip>
      <!--Data Table que mostra as Persons na página inicial-->
      <v-data-table :key="'table-' + tableUpdate" style="width: 100%" v-model="selected" :headers="headers"
        :items="filteredPersons" item-key="name" show-select class="elevation-1" :search="textfilter"
        :items-per-page="-1">
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.birthday="{ item }">
          {{ item.birthday.toLocaleDateString('pt-BR', {
            year: 'numeric', month: '2-digit', day: '2-digit',
            timeZone: 'UTC'
          }) }}
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex flex-no-wrap">
            <!-- ÍCONE DE EDITAR -->
            <v-icon color="primary" small class="mr-2" @click="editItem(item)" title="visualizar">
              mdi-eye-outline
            </v-icon>
            <!-- ÍCONE DE TIME-COUNTER -->
            <v-icon color="black" class="mr-2" small @click="goToTimeCounter(item)" title="Contagem de Tempo">
              mdi-calendar-clock
            </v-icon>
            <!-- ÍCONE DE EXONERAR -->
            <v-icon color="red" small @click="exonerateItem(item)" title="Exonerar"
              :disabled="isAlreadyExonerated(item)">
              mdi-account-cancel
            </v-icon>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon color="black">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="deleteItem(item)">
                  <v-list-item-title>Deletar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="3000" top color="green">
      {{ snackbarMessage }}
      <v-btn class="ml-2" icon text @click="snackbar = false"><v-icon>mdi-hand-peace</v-icon></v-btn>
    </v-snackbar>
    <v-dialog width="80%" v-model="dialog">
      <new-user class="pa-6" @close=" dialog = false; selectedPerson = {};" :update="update"
        :person="this.selectedPerson" @register="addPerson" @itemUpdated="itemUpdated" @update-careers="itemUpdateById">
      </new-user>
    </v-dialog>
  </v-container>
</template>

<script>
import NewUser from "../components/NewUser.vue";
import * as XLSX from 'xlsx';
import axios from 'axios'
const urlbackend = process.env.VUE_APP_API_URL

export default {
  components: {
    NewUser,
  },
  props: {
    value: Array,
    person: Object,
  },
  data() {
    return {
      snackbar: false,
      snackbarMessage: '',
      update: false,
      dialog: false,
      menu: false,
      filteredPersons: [],
      selectedFilter: 'active',
      selected: [],
      textfilter: "",
      tableUpdate: 0,
      selectedPerson: {},
      headers: [
        { text: "Nome", align: "start", sortable: true, value: "name", },
        { text: "Matrícula", value: "careers[0].jobRegister" },
        { text: "Cargo", value: "careers[0].post" },
        { text: "Setor", value: "careers[0].department" },
        { text: "Vínculo", value: "careers[0].bond" },
        { text: "CCA", value: "careers[0].cca" },
        { text: "Ações", value: "actions", sortable: false },
      ],
      persons: [],
      excelFields: {
        'name': 'Nome',
        'email': 'E-mail',
        'datanasc': 'Data de nascimento',
        'jobRegister': 'Matrícula',
        'entryDate': 'Data de Admissão',
        'department': 'Lotação',
        'post': 'Cargo',
        'role': 'Função',
        'gender': 'Gênero',
        'bond': 'Vínculo',
        'birthday': 'Aniversário',
        'isBoss': 'Chefia',

      },
      excelFileName: 'dados_excel',
    };
  },
  created() {
    axios.get(urlbackend + '/rh/person').then((res) => {
      if (res.status == 200 && res.data) {
        this.persons = res.data.map((el) => {
          el.birthday = new Date(el.birthday);
          el.entryDate = new Date(el.entryDate);
          return el;
        });
        this.applyFilters(); // Aplicar o filtro ao carregar os dados
      } else {
        console.error("Resposta inesperada", res);
        alert("Erro ao buscar dados");
      }
    });
  },
  computed: {
    currentFilterLabel() {
      const filterMap = {
        all: "Todos",
        active: "Ativos",
        exonerated: "Exonerados",
      };
      return filterMap[this.selectedFilter] || "Desconhecido";
    },
  },
  methods: {
    //Função usada para formatar a data na exportação de dados para o Excel
    formatDate(value) {
      if (!value) return ''
      var date = new Date(value)
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      date = new Date(date.getTime() + userTimezoneOffset);
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`

    },
    applyFilters() {
      this.persons = this.persons.map((person) => {
        // Verifica se a pessoa tem carreiras e as ordena, se existirem
        if (person.careers && person.careers.length > 0) {
          person.careers = person.careers.sort((a, b) => b.ID - a.ID); // Ordena pela carreira mais recente
        }
        return person;
      });

      this.filteredPersons = this.persons.filter(person => {
        const lastCareer = person.careers && person.careers.length > 0 ? person.careers[0] : null; // Obtém a última carreira, se existir

        if (this.selectedFilter === 'all') {
          // Retorna todas as pessoas, independentemente de terem ou não carreiras
          return true;
        } else if (this.selectedFilter === 'exonerated') {
          // Retorna pessoas exoneradas (somente aquelas com carreiras e exoneração)
          return person.careers.length && !!lastCareer && !!lastCareer.exoneration;
        } else if (this.selectedFilter === 'active') {
          // Retorna pessoas ativas (com carreira e sem exoneração)
          return person.careers.length && !!lastCareer && !lastCareer.exoneration;
        }
        return false;
      });
    },
    goToTimeCounter(person) {
      this.$router.push({ path: `/contagem-tempo/${person.ID}` });
    },
    novoServidor() {
      this.selectedPerson = {};  // Resetar selectedPerson
      this.update = false;  // Resetar update
      this.dialog = true;
    },
    addPerson(person) {
      person.careers = []
      this.persons.push(person);
      this.applyFilters(); // Reaplicar filtros após adicionar nova pessoa
      this.selectedPerson = person;
      this.update = true;
    },
    editItem(item) {
      this.selectedPerson = item;
      this.update = true;
      this.dialog = true;
    },
    deleteItem(item) {
      if (confirm("Tem certeza que deseja excluir este servidor?")) {
        axios.delete(`${urlbackend}/rh/person/${item.ID}`)
          .then((res) => {
            if (res.status >= 200 && res.status <= 299) {
              // Remove o item da lista local após uma exclusão bem-sucedida
              const index = this.persons.findIndex((person) => person.ID === item.ID);
              if (index !== -1) {
                this.persons.splice(index, 1);
                this.applyFilters(); // Reaplicar filtros após exclusão
              }
              // Atualiza a tabela (opcional)
              this.tableUpdate++;
            }
          })
          .catch((error) => {
            console.error("Erro ao excluir o item:", error);
          });
      }
    },
    isAlreadyExonerated(item) {
      const lastCareer = item.careers[0]
      return item.careers.length && !!lastCareer.exoneration
    },
    exonerateItem(item) {
      if (this.isAlreadyExonerated(item)) {
        alert("Este servidor já está exonerado.");
        return;
      }
      if (confirm("Tem certeza que deseja exonerar este servidor?")) {
        let body = item.careers[0];
        let obj = { exoneration: new Date() };
        axios.put(urlbackend + '/rh/career/' + body.ID, obj)
          .then((res) => {
            if (res.status >= 200 && res.status <= 299) {
              const index = this.persons.findIndex((person) => person.ID === item.ID);
              if (index !== -1) {
                this.persons[index].careers[0] = res.data;
                this.applyFilters(); // Reaplicar filtros após exclusão
              }
              this.tableUpdate++;
            }
          })
          .catch((error) => {
            console.error("Erro ao exonerar este servidor", error);
          });
      }
    },

    itemUpdateById(id) {
      const index = this.persons.findIndex((person) => person.ID == id);
      if (index !== -1) {
        axios.get(urlbackend + '/rh/person/' + id)
          .then((res) => {
            if (res.status >= 200 && res.status <= 299) {
              this.persons[index] = res.data;
              this.persons[index].birthday = new Date(this.persons[index].birthday);
              this.applyFilters(); // Reaplicar filtros após atualização
              this.tableUpdate++;
            }
          })
          .catch((error) => {
            console.error("Erro ao atualizar o item:", error);
          });
      }
    },

    itemUpdated(item) {
    this.update = false;
    this.dialog = false;
    this.selectedPerson = {};
    
    // Encontra o índice do item atualizado na lista de pessoas
    const index = this.persons.findIndex(
      (person) => person.ID == item.ID
    );
    
    // Atualiza a pessoa na lista principal
    this.persons[index] = item;
    
    // Reaplica o filtro
    this.applyFilters();
    
    // Atualiza a lista de itens selecionados (caso o item atualizado esteja selecionado)
    this.selected = this.selected.map(selectedPerson => 
      selectedPerson.ID === item.ID ? item : selectedPerson
    );
    
    this.tableUpdate++;
  },
    exportToExcel() {
      if (this.selected.length === 0) {
        alert("Selecione pelo menos uma linha para exportar.");
        return;
      }

      const selectedPersons = this.persons.filter((person) =>
        this.selected.includes(person)
      );

      const formatedPersons = selectedPersons.map((person) => {
        const formattedPerson = {
          "Nome": person.name,
          "Email Institucional": person.email,
          "Email Pessoal" : person.personalEmail,
          "Gênero": person.gender,
          "Data Nascimento": this.formatDate(person.birthday) ? this.formatDate(person.birthday) : '',
          "CPG": person.cpf,
          "RG": person.rg,
          "Órgão Emissor": person.issuingBody,
          "UF": person.uf,
          "Telefone": person.phone,
          "Tem filhos?": person.hasChildren ? 'Sim' : 'Não',
          "Estado Civil": person.maritalStatus,
          "Autodeclarado" : person.selfDeclared,

        };

        if (person.careers.length > 0) {
          const career = person.careers[0];
          formattedPerson["Matricula"] = career.jobRegister ? career.jobRegister : '';
          formattedPerson["Cargo"] = career.post ? career.post : '';
          formattedPerson["Função"] = career.role ? career.role : '';
          formattedPerson["Vínculo"] = career.bond ? career.bond : '';
          formattedPerson["Setor"] = career.department ? career.department : '';
          formattedPerson["Chefia"] = career.isBoss !== undefined ? (career.isBoss ? 'Sim' : 'Não') : '';
          formattedPerson["Data Admissão"] = this.formatDate(career.entryDate) ? this.formatDate(career.entryDate) : '';
          if (career.bossSector){
            formattedPerson["Setor que Chefia"] = career.bossSector;
          }
          console.log("Passou do BossSector");
          formattedPerson["Símbolo"] = career.symbol ? career.symbol : '';
          console.log("Passou do symbol")
          formattedPerson["CCA"] = career.cca ? career.cca : '';
          console.log("Passou do cca")
          formattedPerson["Cidade Lotação"] = career.workCity ? career.workCity : '';
          console.log("Passou do workCity")
          formattedPerson["Orgão Origem"] = career.originOrganization ? career.originOrganization : '';
          console.log("Passou do originOrganization")
          formattedPerson["Abono de Permanência"] = career.bonusPostRetirament ? 'Sim' : 'Não';
          console.log("Passou do bonusPostRetirament " + career.bonusPostRetirament)
          if (career.exoneration) {
            formattedPerson["Data Exoneração"] = this.formatDate(career.exoneration);
          }

        } else {
          formattedPerson["Matricula"] = '';
          formattedPerson["Cargo"] = '';
          formattedPerson["Função"] = '';
          formattedPerson["Vínculo"] = '';
          formattedPerson["Setor"] = '';
          formattedPerson["Chefia"] = '';
          formattedPerson["Data Admissão"] = '';
          formattedPerson["Data Exoneração"] = '';
          formatedPersons["Símbolo"] = '';
          formatedPersons["CCA"] = '';
          formatedPersons["Cidade Lotação"] = '';
          formatedPersons["Orgão Origem"] = '';
          formatedPersons["Abono de Permanência"] = '';
          formatedPersons["Setor que Chefia"] = '';
        }

        return formattedPerson;
      });

      const ws = XLSX.utils.json_to_sheet(formatedPersons);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

      XLSX.writeFile(wb, 'Quadro_de_Pessoal.xlsx');
      // Exibe o feedback de sucesso
      this.snackbarMessage = 'Exportação para Excel realizada com sucesso!';
      this.snackbar = true;
    },
  },
};
</script>

<style></style>