import Vue from 'vue'
import VueRouter from 'vue-router'
import QuatiFront from '../views/QuatiFront.vue'
import QuatiTimeline from '../views/QuatiTimeline.vue'
import QuatiLogin from '../views/QuatiLogin.vue'
import QuatiConfirmation from '../views/QuatiConfirmation.vue'
import Chati from '../views/ChatiView.vue'
import SearchView from '../views/SearchView.vue'
import Home from '../views/Home.vue'
import TimeCounter from '../views/TimeCounter.vue'
import PersonalData from '../views/PersonalData.vue'
//import { component } from 'vue/types/umd'
import QuatiRedefine from '../views/QuatiRedefine.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/prescricao',
    name: 'Prescrição',
    component: QuatiFront,
  },
  {
    path: '/',
    name: 'quaTI',
    component: Home,
  },
  {
    path: '/process/:numberProcess/:debug?',
    name: 'Processo',
    component: QuatiTimeline,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: QuatiLogin
  },
  {
    path: '/contagem-tempo/:person',
    name: 'Contagem de Tempo de Serviço',
    component: TimeCounter,
    props: true

  },
  {
    path: '/rh',
    name: 'Unidade de Recursos Humanos',
    component: PersonalData
  },
  {
    path: '/chati',
    name: 'ChaTI',
    component: Chati
  },
  {
    path: '/verify-email',
    name: 'Confirmação de Email',
    component: QuatiConfirmation
  },
  {
    path: '/reset-password',
    name: 'Redefinição de senha',
    component: QuatiRedefine
  },
  {
    path: '/busca',
    name: 'Busca de Pareceres',
    component: SearchView
  },
]

const router = new VueRouter({
  routes 
})

export default router