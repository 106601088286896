<template>
  <v-container class="custom-container">
    <div class="py-5">
      <h2>Redefinir Senha</h2>
    </div>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="9">
        <v-text-field v-model="newPassword" :rules="[rules.required, rules.min]" label="Nova Senha" :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="9">
        <v-text-field v-model="confirmPassword" :rules="[rules.required, rules.min, rules.match]" label="Confirmar Senha"
          :type="showConfirmPassword ? 'text' : 'password'" :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showConfirmPassword = !showConfirmPassword">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="9">
        <v-btn block color="primary" @click="resetPassword">Redefinir Senha</v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Fechar</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
const urlbackend = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      snackbar: false,
      snackbarMessage: '',
      snackbarColor: 'error',
      timeout: 2000,
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        min: value => value.length >= 6 || 'Senha deve ter pelo menos 6 caracteres.',
        match: value => value === this.newPassword || 'As senhas não coincidem.'
      }
    };
  },
  methods: {
    async resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.snackbarMessage = 'As senhas não coincidem.';
        this.snackbarColor = 'error';
        this.snackbar = true;
        return;
      }
      
      try {
        const token = this.$route.query.token; // Assumindo que o token é passado como query parameter na URL
        const response = await axios.post(`${urlbackend}/auth/reset-password/?token=${token}`, {
          password: this.newPassword
        });
        console.log("Resposta da redefinição de senha:", response);
        this.snackbarMessage = 'Senha redefinida com sucesso!';
        this.snackbarColor = 'success';
        this.snackbar = true;
        setTimeout(() => {
          this.$router.push('/login');
        }, 2000);
      } catch (error) {
        console.error("Erro ao redefinir senha:", error);
        this.snackbarMessage = error.response ? error.response.data.message : 'Erro ao conectar ao servidor';
        this.snackbarColor = 'error';
        this.snackbar = true;
      }
    }
  }
};
</script>

<style>
.custom-container {
  align-items: self-start;
  text-align-last: center;
}
</style>
