<template>
  <v-container v-if="!loading" class="pa-0" style="max-width: 100%; display:default">
    
    <!--Props criada para usar como uma variavel computada, pegando os valores de filteredMovements e usando em outro componente-->
    <v-row :class="$vuetify.breakpoint.xs? '':'fixed-panel'" :style="'top: ' + appbarHeight">
      <v-col cols="12" class="pa-0 pb-5">
        <SummarizedTimeline id="summarized-timeline" ref="SummarizedTimeline" :itensProp="filteredMovements"
          :classes="classes" :subclasses="subclasses" class="elavation-1" :process="process"
          :distributionYear="process.distributionYear" @updateUnified="getUnificado" @updatePrescriptionDate="$emit('updatePrescriptionDate',$event)"/>
      </v-col>
      <v-col cols="12" style="align-items: baseline; justify-content: flex-end;" :style="getFilterHeight()"
        class="fixed-row my-0 py-0">
        <v-row style="justify-content: space-between">
          <v-col cols="2" sm="4" style="align-self: flex-end;">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn  class="hidden-xs-only" dark color="primary" medium @click="searchKeyword ? '' : expandAll()"
                  v-on="searchKeyword ? on : ''">
                  {{ showAll ? 'Ocultar movimentações' : 'Exibir ocultos' }}
                </v-btn>
                <v-btn dark color="primary" medium class="hidden-sm-and-up" @click="searchKeyword ? '' : expandAll()"
                  v-on="searchKeyword ? on : ''">
                  <v-icon>{{ showAll ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                </v-btn>
              </template>
              <span>Desabilitado devido ao filtro textual</span>
            </v-tooltip>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model.lazy="searchKeyword" hide-details dense rounded solo
              placeholder="Pesquise por palavras, pessoas ou empresas" append-icon="mdi-magnify">
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pa-0">
        <v-timeline dense>
          <v-timeline-item fill-dot :small="!n.prescriptionMovement" v-for="(n, index) of filteredMovements"
            :key="`timeline-key-${index}`" class="timeline-item"
            :style="(n.important || showAll) ? '' : 'display: none'" @click.native="activeMovement(index)" :id="n.id">
            <template v-slot:icon>
              <v-icon dark color="yellow" v-if="n.feedback.length > 0">mdi-flag-outline</v-icon>
              <v-btn icon small v-if="n.expand">
                <v-icon dark color="white">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-card class="elevation-4"
              :style="!n.expand && (showAll || n.prescriptionMovement || n.feedback.length || n.active) ? '' : 'display: none'"
              :ref="`timeline-item-${index}`">
              <v-card-title class="text-h6"
                :style="{ 'justify-content': n.prescriptionMovement || n.feedback ? 'space-between' : 'flex-end' }">
                <span v-if="n.feedback.length > 0 || n.prescriptionMovement != ''">
                  {{ n.feedback.length > 0 ? getClassNameById(n.feedback[n.feedback.length - 1].subClass) :
                    n.prescriptionMovement }}
                  <span style="font-size: 15px; vertical-align: sub"
                    v-if="n.feedback.length == 0 && debug === 'true' && n.prescriptionMovement">
                    (score: {{ n.statusTemporary[0].accuracy || "" }})
                  </span>
                  <v-btn v-if="n.feedback.length > 0 || n.prescriptionMovement" icon color="red"
                    @click="setFeedbackToDelete(n)"><v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </span>
                <span class="ml-auto" v-if="n.feedback.length > 0 || n.prescriptionMovement">
                  <v-btn v-if="n.cdDocumento" class="mx-2" color="primary" tile small
                    @click="openInNewTab(n.cdDocumento)">
                    <v-icon>mdi-note</v-icon>
                  </v-btn>
                  <v-btn color="success" tile small @click="feedbackSubclass(n)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </span>
              </v-card-title>
              <v-card-subtitle style="text-align-last: justify">
                <!-- classificação antiga -->
                <div v-if="n.feedback.length > 0">
                  <span class="text-decoration-line-through">{{ n.feedback.length > 0 ? n.prescriptionMovement : ''}}</span>
                  {{ n.dateMovement }}
                </div> 
                <div v-else>
               {{ n.dateMovement }}
                <span class="ml-auto" v-if="!n.feedback.length && !n.prescriptionMovement">
                  <v-btn v-if="n.cdDocumento" class="mx-2" color="primary" tile small
                    @click="openInNewTab(n.cdDocumento)">
                    <v-icon>mdi-note</v-icon>
                  </v-btn>
                  <v-btn color="success" tile small @click="feedbackSubclass(n)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </span>
              </div>
              </v-card-subtitle>
              <v-card-text class="text-justify">
                {{ n.textMovement }}
              </v-card-text>
            </v-card>
            <span v-if="!n.expand || showAll" :ref="`timeline-item-date-${index}`"
              class="font-weight-normal text-caption"
              :style="showAll || n.prescriptionMovement || n.feedback.length || n.active ? 'display: none' : ''">
              {{ n.dateMovement }}
            </span>
            <span v-if="n.expand" class="font-weight-normal text-caption">
              Clique para ver mais... {{ viewMore(index) }}
            </span>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialog_confirmDelete" width="390">
      <v-card>
        <v-card-title style="justify-content: center">Confirmar Exclusão</v-card-title>
        <v-card-text>
          Tem certeza de que deseja excluir esta classificação?
        </v-card-text>
        <v-card-actions style="justify-content: end; background-color: gainsboro">
          <v-btn color="red" text @click="dialog_confirmDelete = false">Cancelar</v-btn>
          <v-btn color="primary" @click="deleteFeedback()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_subclass" width="500">
      <v-card>
        <v-card-title class="py-6" style="justify-content: center">
          {{ `Qual tipo de movimentação é a correta ?` }}
        </v-card-title>
        <v-card-text>
          <v-row class="py-4 px-2" style="border-style: solid; border-color: black;">
            {{ textFeedbackMovement }}
          </v-row>
          <v-row class="py-3">
            <v-combobox outlined v-model="selectedSubclass" label="Selecione um tipo de movimentação"
              :items="addclasses" item-value="id" item-text="name" :search-input.sync="auxfeedback" :menuProps="menuProps">
              <template v-slot:item="{ item }">
                <v-list-item @click="clickedItemClass(item)">
                  <v-list-item-title>
                    <span>{{ item.name }}</span><span v-if="user.user.role == 'adm'" class="text--disabled"> ({{item.count}})</span>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-combobox>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog_subclass = false">Cancelar</v-btn>
          <v-btn :disabled="auxfeedback == null || selectedSubclass == null" color="primary"
            @click="feedbackEffects()">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_class" max-width="510">
      <v-card>
        <v-card-title class="d-flex justify-center" style="background-color: #2196F3; color: white">
          {{ `Qual o efeito de para o prazo?` }} <!--"${getClassNameById(feedback.class)}"-->
        </v-card-title>
        <v-card-text>
          <v-list class="mt-4">
            <v-list-item class="pt-3" style="justify-content: center" v-for="(item, i) in classes" :key="i"
              @click="postFeedback(item)"> <!--ou feedbackClass(item) ID da movimentação da classe e da subclasse-->
              <p style="font-size: 17px">{{ item.name }}</p>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_esaj" max-width="510">
      <v-card>
        <v-card-title class="d-flex justify-center" style="background-color: gold; color: black">
          Essa é uma funcionalidade experimental
        </v-card-title>
        <v-card-text class="mt-2">
          <p><strong>A visualição dos autos ocorre através do sistema ESAJ</strong>, para o funcionamento correto dessa
            funcionalidade você deve autenticar o seu acesso à esse sistema.</p>
          <p>Clique em <strong>"abrir ESAJ"</strong> e realize o seu Login.</p>
          <p>Após o Login, retorne ao Quati e <strong>Confirme seu Login</strong>.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" dark @click="dialog_esaj = false">Cancelar</v-btn>
          <v-btn v-if="!showConfirmLogin" color="primary" dark @click="openLoginESAJ()">Abrir ESAJ</v-btn>
          <v-btn v-if="showConfirmLogin" color="primary" @click="confirmLogin()">Confirmar Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_confirmLogin" max-width="510">
      <v-card class="mt-2">
        <v-card-title class="mt-2">
          Você habilitou o acesso aos autos no esaj
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog_confirmLogin = false">Voltar para o processo</v-btn>
          <v-btn color="green" @click="openInNewTab(documentAuto); dialog_confirmLogin = false">Abrir o Auto</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbarFeedback" :timeout="timeout">
      {{ mensagem }}
      <v-btn color="primary" text @click="snackbarFeedback = false">
        Fechar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios"
import SummarizedTimeline from './SummarizedTimeline.vue'

// Declaração das constantes axios(func)/url do back
const urlbackend = process.env.VUE_APP_API_URL

export default {
  components: {
    SummarizedTimeline
  },
  props: {
    process: Object,
    showAll: Boolean,
    debug: Boolean,
  },
  data() {
    return {
      appbarHeight: "0px",
      classes: [
        {
          name: "",
          id: ""
        }
      ],
      searchKeyword: "", // filtro de busca das movimentaçõs
      feedback: {},
      // filteredMovements: [],
      dialog_confirmDelete: false,
      dialog_class: false,
      dialog_subclass: false,
      snackbarFeedback: false,
      selectedSubclass: {
        name: null,
      },
      feedbackToDelete: null, // Armazena teporariamente o feedback
      addclasses: [],
      mensagem: "",
      timeout: 2000,
      control: 0,
      auxfeedback: "",
      textFeedbackMovement: "",
      clickedClass: false,
      subclasses: [],
      loading: true,
      dialog_esaj: false,
      showConfirmLogin: false,
      dialog_confirmLogin: false,
      documentAuto: "",
      dialog_apensados: false,
      ExpandedIndexes: [],
      openedIndexes: [],
      unificado: false,
      menuProps: {
        closeOnContentClick: true,
      },
      user: null
    }
  },
  computed: {
    // filtro para movimentações com base na palavra-chave de busca
    filteredMovements: {
      get() {
        var self = { expandUntilNextImportant: false }
        var filtered = this.process.movements
        if (this.searchKeyword) {
          const keyword = this.searchKeyword.toLowerCase()
          filtered = filtered.filter((movement) => {
            const textToSearch = movement.textMovement.toLowerCase()
            const dateToSearch = movement.dateMovement.toLowerCase()
            const allClassifications = (this.getClassTittle(movement) || "").toLowerCase()

            return textToSearch.includes(keyword) || dateToSearch.includes(keyword) || allClassifications.includes(keyword)
          })
          filtered = filtered.map((movement) => {
            movement.active = true
            movement.expand = false
            return movement
          })
        }
        else {
          let lastImportant = 0
          filtered = filtered.sort((a, b) => a.pageProcessMovement - b.pageProcessMovement)
          filtered = filtered.map((movement, index) => {
            movement.active = false
            if (this.ExpandedIndexes.includes(index)) {
              this.expandUntilNextImportant = true
            }
            if (this.openedIndexes.includes(index)) {
              movement.active = true
            } 
            if ((movement.statusTemporary.length && movement.statusTemporary.sort((a, b) => a.accuracy > b.accuracy)[0].class.id == "6602d159e7cc3f00666459b4") ||
              (movement.feedback.length && movement.feedback[movement.feedback.length - 1].subClass === "6602d159e7cc3f00666459b4")) {
              movement.feedback = []
              movement.prescriptionMovement = ''
            }
            if (movement.prescriptionMovement || movement.feedback.length > 0) {
              movement.important = true
              lastImportant = 0
              movement.expand = false
              this.expandUntilNextImportant = false
            } else if (this.expandUntilNextImportant) {
              movement.important = true
              lastImportant = 0
              movement.expand = false
            } else if (lastImportant < 2) {
              lastImportant += 1
              movement.important = true
              movement.expand = false
            }
            else if (lastImportant == 2) {
              lastImportant += 1
              movement.important = true
              movement.expand = !this.showAll
            }
            else {
              movement.important = movement.important ? movement.important : false
              lastImportant = movement.important ? lastImportant -= 1 : lastImportant += 1
            }
            return movement
          }, self)
        }
        return filtered
      },
      set(value) {
        console.log('filteredMovements foi modificado:', value)
      }
    },
  },
  async created() {
    //this.hideMovements()
    await this.getClasses()
    await this.getSubClasses()
    this.loading = false
    this.logged = sessionStorage.getItem('logged')
    this.user = JSON.parse(localStorage.getItem("user"))
    if (this.user) {
      this.appbarHeight = parseInt(document.getElementById("appbar").style.height.replace('px', '')) + 12 + 'px'
    }
      
  },
  methods: {
    getUnificado(valueUnified) {
      this.unificado = valueUnified
    },
    formatUpdateDate(date) {
      date = new Date(date).toLocaleDateString('pt-BR')

      return date
    },

    getFilterHeight() {
      //let summarizedHeight = document.getElementById("summarized-timeline").style.height
      let height = 56 + 12 + parseInt(this.appbarHeight.replace('px', ''))
      return `top: ${height}px`
    },
    confirmLogin() {
      this.logged = true
      sessionStorage.setItem('logged', true)
      this.dialog_esaj = false
      this.dialog_confirmLogin = true
    },
    openLoginESAJ() {
      window.open('https://esaj.tjms.jus.br/sajcas/login', '_blank').focus()
      this.showConfirmLogin = true
    },
    async openInNewTab(documento) {
      if (!this.logged) {
        this.dialog_esaj = true
        this.documentAuto = documento
      }
      else {
        let esaj = `https://esaj.tjms.jus.br/cpopg5/showMobile.do?processo.codigo=${this.process.codigo}&processo.foro=1&processo.numero=${this.process.numberProcess}`
        let url = `https://esaj.tjms.jus.br/cpopg5/abrirDocumentoVinculadoMovimentacao.do?processo.codigo=${this.process.codigo}&cdDocumento=${documento}`
        let tab = window.open(esaj, '_blank');
        tab.location.href = esaj.replace('showMobile', 'show')
        await new Promise(r => setTimeout(r, 1000));
        tab.location.href = url
      }
    },

    openAllDocs() {
      let esaj = `https://esaj.tjms.jus.br/cpopg5/showMobile.do?processo.codigo=${this.process.codigo}&processo.foro=1&processo.numero=${this.process.numero}`
      let tab = window.open(esaj, '_blank');
      tab.location.href = `https://esaj.tjms.jus.br/cpopg5/abrirPastaDigital.do?processo.codigo=${this.process.codigo}`
      let newUrl = tab.Window.document.querySelector('body').innerHTML
      tab.location.href = newUrl
    },

    async getSubClasses() {
      await axios.get(urlbackend + "/subclass").then((res) => {
        this.subclasses = res.data
      })
    },

    hideMovements() {
      this.ExpandedIndexes = []
    },

    expandAll() {
      this.showAll = !this.showAll
      if (!this.showAll) {
        this.filteredMovements = this.filteredMovements.map((el) => { el.important = false; el.expand = false; return el })
        this.hideMovements()
      }
      else {
        this.filteredMovements = this.filteredMovements.map((el) => { el.important = true; el.expand = false; return el })
      }
    },

    async getClasses() {
      axios.get(urlbackend + "/class").then((res) => {
        if (res.status == 200) {
          this.classes = res.data
          this.classes = this.classes.map(async (item) => {
            item.subclass = await axios.get(urlbackend + "/subclass/" + item.id).then((res) => {
              return res.data
            })
            this.addclasses.push(...item.subclass)
            this.addclasses.sort((a, b) => a.name.localeCompare(b.name))
            return item
          })
          this.classes = res.data
        }
      })
    },

    postFeedback(newclass) {
      if (newclass) {
        this.feedback.class = newclass.id
        this.feedback.nameNewClass = this.auxfeedback
      }
      else {
        this.feedback.class = this.selectedSubclass.relatesTo
        this.feedback.subClass = this.selectedSubclass.id
      }

      axios.post(urlbackend + "/feedback", this.feedback).then((res) => {
        this.mensagem = 'O feedback foi atualizado com sucesso.'
        this.dialog_class = false
        this.dialog_subclass = false
        this.$emit('updateValidate', false)
        this.filteredMovements = this.filteredMovements.map((el) => {

          if (el.id === res.data.movement) {
            var feedbackCopy = Object.assign({}, res.data);
            el.feedback.push(feedbackCopy);
          }

          return el;
        })
        this.getSubClasses()
        this.feedback = {}
        if (res.data.class == '6509dbedb9924a000753c5f3') {
          axios.post(urlbackend + '/prescriptiondate', { numberProcess: this.process.numberProcess, prescriptionDate: null })
        }
        else
          axios.post(urlbackend + '/prescriptiondate', { numberProcess: this.process.numberProcess, prescriptionDate: this.dateFromBRString(this.$refs.SummarizedTimeline.getPrescriptionDate()) })

      }).catch((er) => {
        this.mensagem = 'Ocorreu um erro ao enviar o feedback. Tente novamente.'
        console.log(er)
      }).finally(() => {
        this.snackbarFeedback = true
      })

    },

    // Essa função serve como auxliar, para armazenar o valor de n da movimentaçao no v-for do timeline-item
    setFeedbackToDelete(aux) {
      this.feedbackToDelete = aux
      this.dialog_confirmDelete = true
    },

    deleteFeedback() {
      if (this.feedbackToDelete) {
        const movement = this.feedbackToDelete
        if (movement.feedback && movement.feedback.length > 0 || movement.prescriptionMovement && movement.prescriptionMovement.length > 0) {
          // Verificar se há feedback para remover
          const feedbackId = {
            movement: movement.id,
            class: "64ac68185fe31ca8ad57a822", // Adicione a classe correspondente
            subClass: "6602d159e7cc3f00666459b4" // Adicione a subclasse correspondente
          }
          axios.post(urlbackend + "/feedback", feedbackId)
            .then((res) => {
              this.mensagem = 'O feedback foi atualizado com sucesso.'
              if (res.data) {
                // Remove o feedback e a classificação
                movement.feedback = []
                movement.prescriptionMovement = ''
                this.$emit('updateValidate', false)
              }
            })
            .catch(() => {
              this.mensagem = 'Ocorreu um erro ao enviar o feedback. Tente novamente.'
            })
            .finally(() => {
              this.snackbarFeedback = true
            })
        } else {
          console.error("Não há feedback para remover.")
        }
        // Limpar o feedbackToDelete e fechar o diálogo de confirmação
        this.feedbackToDelete = null
        this.dialog_confirmDelete = false
      } else {
        console.error("Nenhum feedback definido.")
      }
    },

    feedbackMovement(nParametro) {
      this.feedback.movement = nParametro.id
      this.dialog_class = true
    },

    feedbackClass(itemParametro) {
      if (itemParametro.id == "64ac68185fe31ca8ad57a822") {
        this.feedback.class = itemParametro.id
        this.dialog_subclass = false
        this.postFeedback()
      }
      else {
        this.feedback.class = itemParametro.id
      }
    },

    feedbackSubclass(selectedMovement) {
      this.auxfeedback = ''
      this.selectedSubclass = { name: null }
      this.feedback.movement = selectedMovement.id
      this.textFeedbackMovement = selectedMovement.textMovement
      this.dialog_subclass = true
    },

    feedbackEffects() {
      if (this.clickedClass) {
        this.postFeedback()
        this.dialog_class = false
        this.dialog_subclass = false
      }
      else {
        this.dialog_class = true
        this.dialog_subclass = false
      }
      this.clickedClass = false
    },

    activeMovement(index) {
      if (this.filteredMovements[index].expand) {
        this.ExpandedIndexes.push(index)
      } else {
        this.openedIndexes.push(index)
      }
    },

    getClassNameById(id) {
      let foundClass = this.subclasses.find((el) => el.id == id)
      if (!foundClass) {
        foundClass = this.classes.find((el) => el.id == id)
      }
      return foundClass ? foundClass.name : ''
    },

    viewMore(index) {
      //Clique para ver mais...
      let nextImportantMovement = this.filteredMovements.findIndex((movement, movindex) => movement.important === true && movindex > index)

      if (nextImportantMovement > 0) {
        return nextImportantMovement - index
      }
      return this.filteredMovements.length - index
    },

    clickedItemClass(item) {
      this.clickedClass = true
      this.auxfeedback = item.name
      this.selectedSubclass = item
    },

    getClassTittle(movement) {
      return movement.feedback.length > 0 ? this.getClassNameById(movement.feedback[movement.feedback.length - 1].subClass) :
        movement.prescriptionMovement
    },

    dateFromBRString(dateString) {
      const dateSplit = dateString.split('/')
      const date = new Date(dateSplit[2], dateSplit[1] - 1, dateSplit[0])
      return date
    }
  }
}
</script>

<style scoped>
.fixed-panel {
  position: sticky;
  top: 1;
  z-index: 10;
}

.fixed-row {
  position: sticky;
  top: 22%;
  z-index: 10;
}
</style>