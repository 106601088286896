<template>
    <v-container fluid fill-height>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card>
            <redefine-password></redefine-password>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import RedefinePassword from '../components/RedefinePassword.vue';
  
  export default ({
    components: {
      RedefinePassword
    }
  })
  </script>
  