<template>
  <v-container>
    <v-card>
      <v-card-title>
        Tempo de Serviço
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details></v-text-field>
        <v-btn outlined color="primary" class="mx-4" @click="clearAll">Limpar</v-btn>
        <v-btn icon color="black"
          @click="data.yearlyInfo.splice(data.yearlyInfo.length, 0, { year: 0, allTime: 0, averbacao: 0, faults: 0, licenses: 0, suspensions: 0, total: 0 })"><v-icon
            large color="blue">mdi-plus-circle </v-icon> </v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="data.yearlyInfo" :search="search">
        <template v-slot:item="{ item, index }">
          <tr :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-table-row' : ''">
            <td :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''">
              <div v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__header' : ''">
                Ano</div>
              <v-text-field v-model.lazy.number="item.year" @input.prevent="updateServiceInfos(item)" :rules="[sameyear(item)]" :ref="'campo-'">
              </v-text-field>
            </td>
            <td :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''">
              <div v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__header' : ''">
                Tempo Bruto</div>
              <v-text-field v-model.lazy.number="item.allTime" @input="updateServiceInfos(item)" :rules="[leapyear(item)]">
              </v-text-field>
            </td>
            <td :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''">
              <div v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__header' : ''">
                Averbação</div>
              <v-text-field v-model.lazy.number="item.averbacao" @input="updateServiceInfos(item)" :rules="[leapyear(item)]">
              </v-text-field>
            </td>
            <td :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''">
              <div v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__header' : ''">
                Faltas</div>
              <v-text-field readonly v-model.lazy.number="item.faults" @input="updateServiceInfos(item)" :rules="[limit(item)]">
              </v-text-field>
            </td>
            <td :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''">
              <div v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__header' : ''">
                Licenças</div>
              <v-text-field readonly v-model.lazy.number="item.licenses" @input="updateServiceInfos(item)" :rules="[limit(item)]">
              </v-text-field>
            </td>
            <td :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''">
              <div v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__header' : ''">
                Suspensões</div>
              <v-text-field readonly v-model.lazy.number="item.suspensions" @input="updateServiceInfos(item)" :rules="[limit(item)]">
              </v-text-field>
            </td>
            <td :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''">
              <div v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__header' : ''">
                Total</div>
              <span>{{item.total}}</span>
            </td>
            <td :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row' : ''" style="justify-content: center">
              <div v-if="$vuetify.breakpoint.xs" :class="$vuetify.breakpoint.xs ? 'v-data-table__mobile-row__header' : ''">
              </div>
              <v-btn icon color="black" @click="updatePeriod(index)" class="btn-delete"><v-icon color="red">mdi-delete
                </v-icon> </v-btn>
            </td>
          </tr>
      </template>
    </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

export default {
  props: {
    data: Object
  },
  data() {
    return{
     search: '',
        headers: [
          {
            text: 'Ano',
            align: 'start',
            sortable: false,
            value: 'year',
          },
          { text: 'Tempo Bruto', value: 'allTime' },
          { text: 'Averbação', value: 'averbacao' },
          { text: 'Faltas', value: 'faults' },
          { text: 'Licenças', value: 'licenses' },
          { text: 'Suspensões', value: 'suspensions' },
          { text: 'Total', value: 'total' },
        ],
      }
  },

  methods: {
    leapyear(item) {
      //Se o ano for bissexto
      if (item.year % 4 == 0 && item.year % 100 != 0 || item.year % 400 == 0)
        return item.allTime + item.averbacao <= 366 || "Ultrapassou o limite"
      else
        return item.allTime + item.averbacao <= 365 || "Ultrapassou o limite"
    },
    updateServiceInfos (item) {
      this.totaldays(item)
      this.$emit("update-observation")
    },
    //Calcula o total de dias
    totaldays(item) {
      item.total = item.allTime + item.averbacao - item.faults - item.licenses - item.suspensions
    },
    clearAll() {
        // Limpa (apaga) todos os valores dos campos
        this.data.trabdays = 0
        
      
        // Valores da tabela
        this.data.yearlyInfo = []

      },
    limit(item) {
      if (item.faults > item.allTime + item.averbacao)
        return "Ultrapassou o limite"
      else if (item.licenses > item.allTime + item.averbacao)
        return "Ultrapassou o limite"
      else if (item.suspensions > item.allTime + item.averbacao)
        return "Ultrapassou o limite"
      else if (item.faults + item.licenses + item.suspensions > item.allTime + item.averbacao)
        return "Ultrapassou o limite"
      return true
    },

    //Verifica se ha anos repetidos na tabela
    sameyear(item) {
      const ano = this.data.yearlyInfo.filter(element => element.year === item.year)

      if (ano.length > 1) {
        return "Ano repetido"
      }
      return true
    },

    updatePeriod(index) {
      this.data.yearlyInfo.splice(index, 1)

      if (index == 0 && this.data.yearlyInfo.length > 0) {
        this.data.allPeriod.start = new Date(this.data.yearlyInfo[0].year, 0, 1, 0)
      } else if (index == this.data.yearlyInfo.length && this.data.yearlyInfo.length > 0) {
        this.data.allPeriod.final = new Date(this.data.yearlyInfo[this.data.yearlyInfo.length-1].year, 11, 31, 0)
      }
    }

  },

  mounted() {
    this.data.yearlyInfo.forEach(element => {
      this.totaldays(element)
    });
  },


}


</script>

<style>

</style>