<template>
  <v-container class="pb-12 pt-0">
    <v-row>
      <v-col align="end">
        <v-btn :disabled="service.yearlyInfo.length == 0" depressed color="primary" @click="pdfmaker">Exportar
          PDF</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdfMake from 'pdfmake'
// import pdfFonts from "pdfmake/build/vfs_fonts";
import logo from '../assets/logo_pge.png'
//import TimeCounterVue from '../views/TimeCounter.vue';
//const timeElapsed = Date.now()
//const today = new Date(timeElapsed)
//pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  props: {
    service: Object
  },
  data() {
    return {
      logo: logo
    }
  },
  computed: {
    period() {
      var now = new Date();
      var offset = now.getTimezoneOffset();
      const start = new Date((new Date(this.service.allPeriod.start)).getTime() + offset * 60000)
      const final = new Date((new Date(this.service.allPeriod.final)).getTime() + offset * 60000)

      return `${start.toLocaleDateString('pt-br')}-${final.toLocaleDateString('pt-br')}`
    }
  },
  methods: {
    async getBase64ImageFromURL(url) {
      return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
          var canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;

          var ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);

          var dataURL = canvas.toDataURL("image/png");

          resolve(dataURL);
        };
        img.onerror = error => {
          reject(error);
        };
        img.src = url;
      });
    },
    async pdfmaker() {
      var dd = {
        footer: function (currentPage) {
          return {
            alignment: 'center',
            text: 'Parque dos Poderes – Bloco IV        |        Campo Grande – MS         |        CEP 79.031-310\n\
                  www.pge.ms.gov.br           Página ' + currentPage
            ,
            fontSize: 7,
            margin: [15, 0]
          }
        },
        content: [
          {
            style: 'tableExample',
            table: {
              widths: ['100%'],
              heigths: [0, 0],
              body: [
                [
                  {
                    alignment: 'center',
                    image: await this.getBase64ImageFromURL(this.logo),
                    fit: [100, 100],
                    style: 'header',
                    border: [false, false, false, false]
                  }
                ],
              ]
            }
          },
          {
            style: 'tableExample',
            table: {
              widths: ['60%', '40%'],
              body: [
                [
                  {
                    bold: true,
                    alignment: 'center',
                    fontSize: 10,
                    text: 'MAPA DE TEMPO DE CONTRIBUIÇÃO',
                    border: [true, true, true, true],
                  },
                  {
                    bold: true,
                    fontSize: 10,
                    alignment: 'center',
                    text: [{ text: 'PROCESSO N: ', bold: true }, this.service.userProcess || ''],
                    border: [true, true, true, true],
                  }
                ]
              ]
            }
          },
          {
            style: 'tableExample',
            table: {
              widths: ['100%'],
              body: [
                [
                  {
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'PERÍODO ABRANGIDO: ', bold: true }, this.period || ''],
                    border: [true, true, true, true],

                  }
                ]
              ]
            }
          },
          {
            style: 'tableExample',
            table: {
              widths: ['100%'],
              body: [
                [
                  {
                    fontSize: 8,
                    bold: true,
                    alignment: 'center',
                    text: 'IDENTIFICAÇÃO',
                    border: [true, true, true, true],
                  }
                ]
              ]
            }
          },
          {
            style: 'tableExample',
            table: {
              widths: ['30%', '25%', '25%', '20%'],
              body: [
                [
                  {
                    bold: true,
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'NOME: \n', bold: true }, this.service.nameService || ''],
                    border: [true, true, true, true],
                    //rowSpan:2,
                  },
                  {
                    bold: true,
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'DATA DE NASCIMENTO:\n', bold: true }, this.service.userBirthDate],
                    border: [true, true, true, true],
                    // rowSpan:2,
                  },
                  {
                    bold: true,
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'MATRÍCULA:\n', bold: true }, this.service.userRegistration || ''],
                    border: [true, true, true, true],
                    //rowSpan:2,
                  },
                  {
                    bold: true,
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'IDADE:\n', bold: true }, this.service.userAge || ''],
                    border: [true, true, true, true],
                    // rowSpan:2,
                  },
                ]
              ]
            }
          },
          {
            style: 'tableExample',
            table: {
              widths: ['30%', '24%', '22%', '24%'],
              body: [
                [
                  {
                    bold: true,
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'CARREIRA:\n', bold: true }, this.service.userCareer || ''],
                    border: [true, true, true, true],
                  },
                  {
                    bold: true,
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'FUNÇÃO:\n', bold: true }, this.service.userFunction || ''],
                    border: [true, true, true, true],
                  },
                  {
                    bold: true,
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'QUADRO:\n', bold: true }, this.service.userBoard || ''],
                    border: [true, true, true, true],
                    //rowSpan:2,
                  },
                  {
                    bold: true,
                    fontSize: 8,
                    alignment: 'center',
                    text: [{ text: 'LOTAÇÃO:\n', bold: true }, this.service.userCapacity || ''],
                    border: [true, true, true, true],
                    // rowSpan:2,
                  },
                ]
              ]
            }
          },
          {
            style: 'tableExample',
            table: {
              widths: ['10%', '10%', '11%', '11%', '12%', '12%', '12%', '12%', '10%'],
              body: [
                [
                  {
                    fontSize: 8,
                    bold: true,
                    alignment: 'center',
                    text: 'ANO',
                    border: [true, true, true, true],
                    rowSpan: 2,
                  },
                  {
                    fontSize: 8,
                    bold: true,
                    rowSpan: 2,
                    alignment: 'center',
                    text: 'TEMPO BRUTO',
                    border: [true, true, true, true],
                  },
                  {
                    fontSize: 8,
                    bold: true,
                    rowSpan: 2,
                    alignment: 'center',
                    text: 'AVERBAÇÃO',
                    border: [true, true, true, true],
                  },
                  {
                    fontSize: 8,
                    bold: true,
                    text: 'DEDUÇÕES',
                    alignment: 'center',
                    border: [true, true, true, true],
                    colSpan: 5,
                  },
                  {
                  },
                  {
                  },
                  {
                  },
                  {
                  },
                  {
                    fontSize: 8,
                    bold: true,
                    alignment: 'center',
                    text: 'TEMPO LÍQUIDO',
                    rowSpan: 2,
                    border: [true, true, true, true],
                  },
                ],
                [
                  {
                  },
                  {
                  },
                  {
                  },
                  {
                    fontSize: 7,
                    bold: true,
                    text: 'FALTAS',
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    fontSize: 7,
                    bold: true,
                    text: 'LICENÇAS',
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    fontSize: 7,
                    bold: true,
                    text: 'SUSPENSÕES',
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    fontSize: 7,
                    bold: true,
                    text: 'OUTROS',
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                    fontSize: 7,
                    bold: true,
                    text: 'TOTAL',
                    alignment: 'center',
                    border: [true, true, true, true],
                  },
                  {
                  },
                ],
              ]
            }
          }
        ]
      }
      var sum = [0, 0, 0, 0, 0, 0, 0]
      for (const element of this.service.yearlyInfo) {
        dd.content[6].table.body.push(
          [
            {
              fontSize: 8,
              bold: true,
              alignment: 'center',
              text: element.year,
              border: [true, true, true, true],
            },
            {
              fontSize: 8,
              bold: true,
              alignment: 'center',
              text: element.allTime,
              border: [true, true, true, true],
            },
            {
              fontSize: 8,
              bold: true,
              alignment: 'center',
              text: element.averbacao,
              border: [true, true, true, true],
            },
            {
              fontSize: 7,
              bold: true,
              text: element.faults,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              fontSize: 7,
              bold: true,
              text: element.licenses,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              fontSize: 7,
              bold: true,
              text: element.suspensions,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              fontSize: 7,
              bold: true,
              text: '....',
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              fontSize: 7,
              bold: true,
              text: element.allTime + element.averbacao,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              fontSize: 8,
              bold: true,
              alignment: 'center',
              text: (element.allTime + element.averbacao) - (element.suspensions + element.faults + element.licenses),
              border: [true, true, true, true],
            },
          ]
        )
        sum[0] = sum[0] + element.allTime,
          sum[1] = sum[1] + element.averbacao,
          sum[2] = sum[2] + element.faults,
          sum[3] = sum[3] + element.licenses,
          sum[4] = sum[4] + element.suspensions,
          sum[5] = sum[5] + element.averbacao + element.allTime,
          sum[6] = sum[6] + ((element.allTime + element.averbacao) - (element.suspensions + element.faults + element.licenses))
      }
      dd.content[6].table.body.push(
        [
          {
            fontSize: 8,
            bold: true,
            alignment: 'center',
            text: 'TOTAL',
            border: [true, true, true, true],
          },
          {
            fontSize: 8,
            bold: true,
            alignment: 'center',
            text: sum[0],
            border: [true, true, true, true],
          },
          {
            fontSize: 8,
            bold: true,
            alignment: 'center',
            text: sum[1],
            border: [true, true, true, true],
          },
          {
            fontSize: 7,
            bold: true,
            text: sum[2],
            alignment: 'center',
            border: [true, true, true, true],
          },
          {
            fontSize: 7,
            bold: true,
            text: sum[3],
            alignment: 'center',
            border: [true, true, true, true],
          },
          {
            fontSize: 7,
            bold: true,
            text: sum[4],
            alignment: 'center',
            border: [true, true, true, true],
          },
          {
            fontSize: 7,
            bold: true,
            text: '....',
            alignment: 'center',
            border: [true, true, true, true],
          },
          {
            fontSize: 7,
            bold: true,
            text: sum[5],
            alignment: 'center',
            border: [true, true, true, true],
          },
          {
            fontSize: 8,
            bold: true,
            alignment: 'center',
            text: sum[6],
            border: [true, true, true, true],
          },
        ]
      )
      dd.content.push(
        {
          fontSize: 7,
          style: 'tableExemple',
          table: {
            bold: true,
            widths: ['100%'],
            body: [[
              {
                text: this.service.observations
              }
            ]
            ]
          }
        },
        {
          style: 'tableExemple',
          table: {
            bold: true,
            widths: ['20%', '30%', '50%'],
            body: [[
              {
                fontSize: 7,
                bold: true,
                text: 'Elaborado Por ',
                border: [true, true, false, true],
              },
              {
                fontSize: 7,
                bold: true,
                text: this.service.elabored + '.\n\
                COPGE/UGP/DAP.\n ',
                border: [false, true, false, true]
              },
              {
                fontSize: 7,
                bold: true,
                alignment: 'right',
                text: 'Campo Grande, MS  ' + this.service.emissao,
                border: [false, true, true, true]
              }
            ]
            ]
          }
        }
      )
      // pdfMake.createPdf(dd).download()
      pdfMake.createPdf(dd).download(this.service.nameService + '.pdf')
    },
    clearAll() {
      this.service = {
        allPeriod: {},
        nameService: "",
        emissao: "",
        userProcess: "",
        userRegistration: "",
        userBirthDate: "",
        userAge: "",
        userClass: "",
        userCode: "",
        userBoard: "",
        userCareer: "",
        userFunction: "",
        userCapacity: "",
        observations: '',
        yearlyInfo: [
          /*{
            year: 2010,
            allTime: 310, // mudar para timestamp do início e do fim
            averbacao: 0,
            faults: 10,
            licenses: 5,
            suspensions: 2,
            total:null
          } */
        ]
      }
    }
  },
}

</script>

<style></style>