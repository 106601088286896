<template :distributionYear="process.distributionYear">
  <v-container>
    <v-card>
      <v-card-title style="justify-content: center; word-break: break-word;">PROCESSOS BUSCADOS
        ANTERIORMENTE</v-card-title>
      <v-card-subtitle class="pt-1" single-line>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisa textual" single-line hide-details>
          <template v-slot:prepend>
            <v-btn @click="filterDialog = true" icon><v-icon>mdi-filter-variant</v-icon></v-btn>
          </template>
        </v-text-field>
      </v-card-subtitle>
      <v-data-table :custom-sort="sortColumns" dense :headers="headers"
        :items="(filteredStatus.length != 0) ? filteredArray : processArray" :search="search" :mobile-breakpoint="0">
        <template v-slot:item="{ item }">
          <v-tooltip top color="error">
            <template v-slot:activator="{ on, attrs }">
              <tr v-bind="attrs" v-on="item.debtValue.length == 0 ? on : null">
                <td @click="redirect(item)">{{ item.numberProcess }}</td>
                <td @click="redirect(item)">{{ formatCurrency(item.debtValue) }}</td>
                <td @click="redirect(item)">{{ item.judge }}</td>
                <td @click="redirect(item)">{{ item.distribution }}</td>
                <td @click="redirect(item)">{{ item.court }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip outlined :color="changeColor(item)" v-bind="attrs" v-on="on">
                        {{ item.datePrescription }}
                      </v-chip>
                    </template>
                    <!-- Conteúdo do tooltip -->
                    <span>{{ item.tooltip }}</span>
                  </v-tooltip>
                </td>
                <td @click="redirect(item)">{{ item.defendant }}</td>
                <td>
                  <v-btn icon @click="deleteProcessItem(item)">
                    <v-icon color="blue">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <span>ERRO! Número do processo inválido, tente novamente.</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="filterDialog" width="500">
      <v-card>
        <v-card-title>Status do processo</v-card-title>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="prescritos" @change=filterItens()></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            Prescritos
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="proxPrescricao" @change=filterItens()></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            Próximos a prescrição
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-checkbox v-model="emAndamento" @change=filterItens()></v-checkbox>
          </v-list-item-action>
          <v-list-item-content>
            Em andamento
          </v-list-item-content>
        </v-list-item>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="filterDialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'
import formatter from '../utils/formatter.js'
const urlbackend = process.env.VUE_APP_API_URL;

export default {
  props: {
    nprocesso: Number,
    process: Object,
    distributionYear: String
  },

  data() {
    return {
      search: '',
      prescritos: '',
      emAndamento: '',
      proxPrescricao: '',
      processArray: [],
      filteredStatus: [],
      filterDialog: false,
      filteredArray: [],
      headers: [
        { text: 'Número do processo', value: 'numberProcess' },
        { text: 'Valor da dívida', value: 'debtValue' },
        { text: 'Juiz', value: 'judge' },
        { text: 'Distribuição', value: 'distribution' },
        { text: 'Vara', value: 'court' },
        { text: 'Data de prescrição', value: 'datePrescription' },
        { text: 'Executado(s)', value: 'defendant' },
        { text: '', sortable: false, }
      ],
      showTooltip: false,
    }
  },

  mounted() {
    axios.get(urlbackend + '/process').then((response) => {
      if (response.data) {
        let oldProcessesArray = response.data;
        oldProcessesArray.forEach((process) => {
          process.numberProcess = process.numberProcess.replace(/\D/g, '')
          var treatProcess = {
            numberProcess: process.numberProcess ? formatter.processNumberFormat(process.numberProcess) : "",
            debtValue: process.debtValue ? parseFloat(process.debtValue) : 0,
            judge: process.judge ? process.judge : "",
            distribution: process.distribution ? process.distribution : "",
            court: process.court ? process.court : "",
            defendant: process.defendant ? process.defendant.join(", ") : "",
            inScrapping: process.inScrapping ? process.inScrapping : false
          }

          if (process.unified) {
            treatProcess.datePrescription = 'unificado'
          } else {
            // Verifica por dia mes e ano se eh menor que 21/06/2005
            if (this.isMinorThenThreshold(process.distribution)) {
              treatProcess.datePrescription = process.prescriptionDateFeedback ? process.prescriptionDateFeedback : process.prescriptionDateLEF
            } else {
              // Caso contrário, usar a data da RESP ou do feedback do usuário
              treatProcess.datePrescription = process.prescriptionDateFeedback ? process.prescriptionDateFeedback : process.prescriptionDate
            }
            treatProcess.datePrescription = treatProcess.datePrescription ? (new Date(treatProcess.datePrescription)).toLocaleDateString('pt-BR') : ""
          }
          treatProcess.color = this.changeColor(treatProcess)
          treatProcess.tooltip = treatProcess.color == "primary" ? "Próximo a prescrever" : treatProcess.color == "green lighten-1" ? "Em andamento" : "Prescrito"
          this.processArray.push(treatProcess)
        })
      }
    }).catch((error) => {
      console.error(error)
    })
  },

  computed: {
    headersWithSort() {
      return this.headers.map(header => {
        if (header.value === 'distribution') {
          return { ...header, customSort: this.customSortByDate };
        }
        return header;
      });
    }
  },

  methods: {
    isMinorThenThreshold(distribution) {
      console.log(distribution)
      if (!distribution) { return false }
      const distributionDate = distribution.split(" ")[0]; // Extrai apenas a parte da data
      const parts = distributionDate.split("/"); // Divide a data em partes usando "/"
      const distributionDay = parseInt(parts[0]); // Extrai o dia
      const distributionMonth = parseInt(parts[1]); // Extrai o mês
      const distributionYear = parseInt(parts[2]); // Extrai o ano

      const dayThreshold = 19
      const monthThreshold = 6
      const yearThreshold = 2005

      if ( // Verifica por dia mes e ano se eh menor que 21/06/2005
        distributionYear < yearThreshold ||
        (distributionYear === yearThreshold && distributionMonth < monthThreshold) ||
        (distributionYear === yearThreshold && distributionMonth === monthThreshold && distributionDay < dayThreshold)
      ) {
        return true
      } else {
        return false
      }
    },

    filterItens() {
      this.filteredStatus = []
      if (this.prescritos) this.filteredStatus.push("amber lighten-1")
      if (this.emAndamento) this.filteredStatus.push("green lighten-1")
      if (this.proxPrescricao) this.filteredStatus.push("primary")
      if (this.filteredStatus.length != 0) {
        this.filteredArray = this.processArray.filter((el) => {
          return this.filteredStatus.includes(el.color)
        })
      }
    },

    changeColor(item) {
      var partes = item.datePrescription.split('/');
      var dia = parseInt(partes[0], 10);
      var mes = parseInt(partes[1], 10) - 1; // Meses começam do zero em JavaScript
      var ano = parseInt(partes[2], 10);

      // Criar uma instância de Date com os componentes extraídos
      var data = new Date(ano, mes, dia);
      var diasPassados = data - new Date()
      var hoje = new Date()
      var seteDias = hoje.setDate(hoje.getDate() + 7);

      //Verificar cor do chip dependendo do tempo para prescrição
      if (diasPassados > 0) {
        if (data < seteDias)
          return "primary"
        else
          return "green lighten-1"
      }
      else if (diasPassados < 0) {
        return "amber lighten-1"
      }
    },

    sortColumns(items, sortBy, sortDesc) {
      if (sortBy == 'datePrescription' || sortBy == 'distribution') {
        items.sort((a, b) => {
          var dataA, dataB
          sortBy == 'distribution' ? (dataA = a.distribution.split(" ")[0].split("/"), dataB = b.distribution.split(" ")[0].split("/")) :
            (dataA = a.datePrescription.split("/"), dataB = b.datePrescription.split("/"))
          var dateA = new Date(+dataA[2], dataA[1] - 1, +dataA[0]);
          var dateB = new Date(+dataB[2], dataB[1] - 1, +dataB[0]);
          if (a == 'unificado') {
            dateA = new Date()
          }
          if (b == 'unificado') {
            dateA = new Date()
          }
          return sortDesc[0] ? dateA - dateB : dateB - dateA;
        });
        return items
      }

      else if (sortBy == 'judge' || sortBy == 'court' || sortBy == 'numberProcess' || sortBy == 'defendant') {
        sortDesc[0] ? (items.sort((a, b) => { return a[sortBy].localeCompare(b[sortBy]) })) :
          (items.sort((a, b) => { return b[sortBy].localeCompare(a[sortBy]) }))
        return items
      }

      else if (sortBy == 'debtValue') {
        sortDesc[0] ? (items.sort((a, b) => { return a.debtValue - b.debtValue })) :
          (items.sort((a, b) => { return b.debtValue - a.debtValue }))
        return items
      }
      return items
    },

    redirect(item) {
      var nprocess;
      nprocess = item.numberProcess.replace(/\D/g, '')
      if (!item.inScrapping)
        this.$router.push({ path: '/process/' + nprocess })
    },
    // Formata o valor do processo na tabela
    formatCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
    // Formata o valor da distribuição na tabela, para que a tabela ordene certo
    customSortByDate(items, sortBy, sortDesc) {
      return items.sort((a, b) => {
        const dateA = new Date(a.distribution.split(" - ")[0].split("/").reverse().join("-"))
        const dateB = new Date(b.distribution.split(" - ")[0].split("/").reverse().join("-"))

        if (dateA.getTime() === dateB.getTime()) {
          return 0
        }

        return (dateA < dateB ? -1 : 1) * (sortDesc ? 1 : -1)
      })
    },
    // Enviar uma solicitação DELETE para o backend para remover o processo
    async deleteProcessItem(item) {
      const nProcess = item.numberProcess.replace(/\D/g, '')
      axios.delete(urlbackend + '/process/' + nProcess).then((res) => {
        if (res.status == 200) {
          const index = this.processArray.findIndex(process => process.numberProcess === item.numberProcess)
          if (index !== -1) {
            this.processArray.splice(index, 1)
          }
        }
      })
    }
  }
}
</script>

<style>
.v-data-table-header {
  background-color: #1976d2 !important;
  color: white;
}

th {
  color: white !important;
}

th i {
  color: white !important;
}

.v-data-table v-card-subtitle {
  max-width: 100px;
  /* Ajuste o valor conforme necessário */
}
</style>
