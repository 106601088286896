<template>
  <v-app>
    <v-app-bar id="appbar" class="fixed-panel pr-2" app color="primary" dark clipped-left>
      <v-app-bar-nav-icon v-if="$route.path == '/'" @click="showMenu = !showMenu">
        <v-icon v-if="!showMenu">mdi-menu</v-icon>
        <v-icon v-else>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-btn icon small
          v-if="$route.path != '/' && !$route.path.includes('login') && !this.$route.path.includes('verify-email') && !this.$route.path.includes('reset-password')"
          @click="voltarHome">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h3 class="pl-3 ma-auto">{{ $route.name }}</h3><span v-if="environment"
          style="align-self: self-end;font-size:0.7rem">{{ environment }}</span>
      </div>
      <v-spacer></v-spacer>
       <div v-if="$route.path != '/login' && $route.path != '/verify-email/'"><notifications-button class="mr-2"></notifications-button></div>
       <user-avatar v-if="!$route.path.includes('login') && !this.$route.path.includes('verify-email') && !this.$route.path.includes('reset-password')"></user-avatar>
    </v-app-bar>
    <v-main class="grey lighten-5">
      <router-view />
    </v-main>
    <v-snackbar v-model="showError" :timeout="timeout" color="error">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showError = false" dark>
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
    <v-navigation-drawer v-model="showMenu" app clipped hide-overlay>
      <v-list nav dense class="px-0 pt-0">

        <v-list-item-group v-model="group" active-class="primary--text text--accent-4">
          <v-list-item @click="$router.push('/')">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Início</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="$router.push('/prescricao')">
            <v-list-item-icon>
              <v-icon>mdi-book-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Prescrição</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item @click="$router.push('/chati')">
            <v-list-item-icon>
              <v-icon>mdi-chat-question-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ChaTI</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-divider></v-divider>
    <v-footer v-if="$route.path === '/'" padless>
      <span class="ml-2"><strong>Apoio:</strong></span>
      <v-col cols="12" style="text-align: -webkit-center;">
        <v-row>
          <v-col cols="2" md="2" style="align-self: center;">
            <v-img src="@/assets/pge_logo.webp" alt="logo pge" width="150px"></v-img>
          </v-col>
          <v-col cols="2" md="2" style="align-self: center;" class="pl-4">
            <v-img src="@/assets/cige_logo.webp" alt="logo cige" width="100px"></v-img>
          </v-col>
          <v-col cols="2" md="2" style="align-self: center;">
            <v-img src="@/assets/fundect_logo.webp" alt="logo fundect" width="150px"></v-img>
          </v-col>
          <v-col cols="2" md="2" style="align-self: center;">
            <v-img src="@/assets/lia_logo.webp" alt="logo lia" width="200px"></v-img> </v-col>
          <v-col cols="2" style="align-self: center;">
            <v-img src="@/assets/ufms_logo.webp" alt="logo ufms" max-width="100px"></v-img>
          </v-col>
          <v-col cols="2" md="2" style="align-self: center;">
            <v-img src="@/assets/Logo_SETDIG.webp" alt="logo " max-width="200px"></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import UserAvatar from './components/UserAvatar.vue'
import NotificationsButton from './components/NotificationsButton.vue';
const environment = process.env.VUE_APP_ENVIRONMENT
import axios from 'axios';
export default {
  name: 'App',
  components: {
    UserAvatar,
    NotificationsButton
  },
  data: () => ({
    showMenu: false,
    group: null,
    showLogout: false,
    user: null,
    showError: false,
    timeout: 2000,
    errorMessage: '',
    environment: environment
  }),
  methods: {
    voltarHome() {
      if (this.$route.path.includes('process')) {
        this.$router.push('/prescricao');
      } else if (this.$route.path.includes('/contagem-tempo')) {
        this.$router.push('/rh');
      } else {
        this.$router.push('/');
      }
    }
  },
  created() {
    const now = new Date()
    this.user = localStorage.getItem('user')
    if (this.$route.path == '/login' && this.user && this.user?.tokens?.access?.expires && new Date(this.user.tokens.access.expires) < now) {
      this.$router.push('/')
    }
    else {
      if (!this.$route.path.includes('verify-email') && !this.$route.path.includes('reset-password')) {
        if (this.user) {
          this.user = JSON.parse(localStorage.getItem('user'))
          if (!this.user.tokens.access.expires || new Date(this.user.tokens.access.expires) < now) {
            this.$router.push('/login');
          }
          else {
            this.showLogout = true
            axios.defaults.headers.common['Authorization'] = `Bearer ${this.user.tokens.access.token}`
          }

        } else {
          this.$router.push('/login');
        }
      }
    }
      axios.interceptors.response.use((response) => response, (error) => {
        if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
          this.$router.push('/login');
        }
        this.errorMessage = error.response.data.message
        this.showError = true
        console.log(error)
      });
  },
  watch: {
    user(val) {
      if (val && (!val.tokens.access.expires || new Date(val.tokens.access.expires) < new Date())) {
        this.showLogout = true
      }
    }
  }
}
</script>

<style>
.fixed-panel {
  position: sticky;
  top: 0;
  z-index: 15;
  /* Ajuste a ordem conforme necessário */
}
</style>