<template>
  <v-container fluid fill-height class="py-0 my-0">
    <v-row justify="center" style="height: 100%">
      <v-col style="height: 100%;" class="py-0 my-0 px-0">
        <v-card style="height: 100%;" class="py-0 my-0 px-0">
          <initial-frame></initial-frame>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InitialFrame from "../components/InitialFrame.vue";

export default {
  components: {
    InitialFrame
  }
}
</script>