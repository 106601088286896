<template>
  <v-container style="font-size: x-large; text-align-last: center;">
    <div v-if="!doneVerification" style="font-size: x-large; text-align-last: center; ">
        <v-progress-circular class="ml-2" :size="60" :width="7" color="blue" indeterminate>
        </v-progress-circular>
        <div class="pt-2" style="word-break: break-word;"><strong>Verificando o email.</strong></div>
      </div>
    

      <v-row v-else-if="error">
        <v-col cols="12">
          <v-icon x-large color="error" style="font-size: 64px;">mdi-alert-circle</v-icon>
        </v-col>
        <v-col cols="12" style="text-align: center;">
          <span v-if="user.isEmailVerified">
          <strong class="pt-2" style="word-break: break-word;">O email cadastrado já foi verificado.</strong>
        </span>
          <span v-else>
          <strong class="pt-2" style="word-break: break-word;">Não foi possível verificar o email, tente novamente.</strong>
        </span>

        </v-col>
    </v-row>

      <div v-else style="font-size: x-large; text-align-last: center;">
        <v-icon x-large  color="success" style="font-size: 64px;">mdi-check-circle</v-icon>
        <div><strong class="pt-2" style="word-break: break-word;">Email verificado.</strong></div>
      </div>
      <div style="justify-content: center;">
        <v-btn text color="primary" v-if="doneVerification" @click="pushLogin()">Voltar ao sistema</v-btn>
      </div>
  </v-container>
</template>

<script>
const urlbackend = process.env.VUE_APP_API_URL
import axios from 'axios'

export default {
  data() {
    return {
      tokenEmail: "",
      doneVerification: true,
      error: false,
    }
  },

  methods: {
    pushLogin() {
      localStorage.removeItem('user')
      this.$router.push('/login')
    },
    verifyEmail() {
      this.tokenEmail = this.$route.query.token
      axios.post(urlbackend + "/auth/verify-email?token="+ this.tokenEmail ).then((res) => {
        this.doneVerification = true
        console.log(res)
      }).catch((err) => {
        this.error= true
        console.log(err)
      })
    },
  },

  created() {
    this.verifyEmail()
    this.user = JSON.parse(localStorage.getItem('user')).user
  },
}


</script>

<style>
.class-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: x-large;
}
</style>
