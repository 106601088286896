import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from "vuetify/src/locale/pt.ts";

Vue.use(Vuetify);

Vue.component('quati-front', {
    methods: {
      changeLocale () {
        this.$vuetify.lang.current = 'ptbr'
      },
    },
  })

const opts = {
    lang: {
      locales: { ptbr: pt },
      current: "ptbr",
    },
  };

export default new Vuetify(opts);
