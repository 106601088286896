<template>
    <v-container dense class="ckeditor pa-0 pr-2">
        <v-row>
            <v-btn class="mr-2" outlined @click="exportToDocx()">Exportar Documento</v-btn>
            <v-btn outlined @click="getRelatorio()">Gerar Relatório</v-btn>
        </v-row>
        <v-row class="py-2 mb-15" v-if="!errorLoad">
            <ckeditor :editor="editor" v-model="editorData" :config="editorConfig">
                </ckeditor>
        </v-row>
    </v-container>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import HTMLtoDOCX from 'html-to-docx';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt';



export default {
    props: {
        process: Object,
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                language: 'pt',
                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',],
            },
            editorData: `
            EXCELENTÍSSIMO SENHOR JUIZ DE DIREITO DA ${this.process.court}  DA COMARCA DE [ÓRGÃO DO PROCESSO
JUDICIAL]<br>
[Endereço Fone/Fax da Procuradoria] - ${this.process.numberProcess}<br>
www.pge.ms.gov.br NN. [Nosso Número] [Local Físico] Página 1

[Tipo de ação do processo judicial] nº 
CDA Nº: [Número da CDA selecionada]
[Tipo de participação da parte ativa principal]: ${this.process.defendant}<br>
[Tipo de participação da parte passiva principal]: [Nome da parte passiva principal]<br>
O ESTADO DE MATO GROSSO DO SUL vem, por seu(ua)<br>
Procurador(a) do Estado, reconhecer a prescrição intercorrente de seu crédito, nos<br>
termos da tese fixada em sede de Recurso Repetitivo sob o Tema 566¹, o que faz com<br>
fulcro no art. 1° parágrafo único, inciso II, da Lei Complementar Estadual 106/2004²,<br>
c/c art. 924, V do CPC.<br>
Por consequência do reconhecimento da prescrição, requer a extinção
do feito.<br>
Pede deferimento.<br>

¹ O prazo de 1 (um) ano de suspensão do processo e do respectivo prazo prescricional previsto no art. 40, §§ 1º e
2º da Lei n. 6.830/80 - LEF tem início automaticamente na data da ciência da Fazenda Pública a respeito da não
localização do devedor ou da inexistência de bens penhoráveis no endereço fornecido, havendo, sem prejuízo
dessa contagem automática, o dever de o magistrado declarar ter ocorrido a suspensão da execução.
<br>
² Art. 1º A Procuradoria-Geral do Estado reconhecerá de ofício a prescrição dos créditos do Estado inscritos em
dívida ativa, de origem tributária ou não-tributária.<br>
Parágrafo único. O reconhecimento da prescrição dar-se-á:<br>
II - em relação aos créditos inscritos em dívida ativa e cujas execuções fiscais forem suspensas com fundamento
no art. 40 da Lei de Execuções Fiscais, depois de decorridos cinco anos da suspensão e após a constatação da
inexistência de bens dos devedores.`,
            errorLoad: false,
            documentFlag: true,
            isOutlined: true,

        }
    },
    methods: {
        getRelatorio() {
            if (this.errorLoad) {
                this.isOutlined = false;
                setTimeout(() => {
                    this.isOutlined = true;
                }, 1000);
            }
        },

        exportToDocx() {
            if (!this.errorLoad) {
                (async () => {
                    const fileBuffer = await HTMLtoDOCX(this.editorData, null, {
                        table: { row: { cantSplit: true } },
                        footer: true,
                        pageNumber: true,
                    });

                    let file = URL.createObjectURL(fileBuffer)
                    let a = document.createElement('a');
                    a.download = 'example.docx';
                    a.href = file;
                    a.click();
                })();
            }
            else {
                this.isOutlined = false;
                setTimeout(() => {
                    this.isOutlined = true;
                }, 1000);
            }
        },
    },

    created() {
        this.getRelatorio()
    },

}


</script>

<style scoped>
.ck-editor__editable_inline {
    max-width: fit-content;
    /* Ajuste conforme necessário */
    width: 100%;
    box-sizing: border-box;
    /* Inclui padding e border no cálculo da largura */
}

/* Certifique-se de que o CKEditor não esteja centralizado */
.ck-editor {
    display: block;
    margin: 0;
    /* Remove qualquer margem que possa estar centralizando o editor */
}
</style>