<template>
    <v-container fluid fill-height>
      <v-row justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card>
            <verify-email></verify-email>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import VerifyEmail from '../components/VerifyEmail.vue';
  
  export default ({
    components: {
      VerifyEmail
    },
  })
  </script>
  