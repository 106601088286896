<template>
    <v-container fill-height class="py-10" :fluid="showChat" style="align-content: center;">
        <!--<v-row justify="center" class="py-5">
        <h1 class="font-weight-black">ANÁLISE PRESCRIÇÃO INTERCORRENTE</h1>
      </v-row>-->
        <v-row class="mx-2">
            <v-col :cols="showChat? '8':'12'">
                <v-row justify="center" class="pb-2">
                    <v-container>
                        <v-fade-transition>
                            <v-row justify="center" v-if="!answers.length">
                                <v-img src="../assets/quati_final.png" alt="logo quaTI" max-width="300"></v-img>
                            </v-row>
                        </v-fade-transition>
                        <v-row justify="center" align="center">
                            <v-fade-transition>
                                <v-col cols="2" v-if="answers.length" style="text-align: -webkit-right;">
                                <v-img src="../assets/quati_final.png" alt="logo quaTI" max-width="80"></v-img>
                            </v-col>
                            </v-fade-transition>
                            
                            <v-col xs="10" :sm="showChat? '10':'8'" :md="showChat? '10':'8'" :lg="showChat? '10':'6'">
                                <!-- <v-text-field label="O que deseja buscar em um parecer?" v-model.lazy="searchInput"
                                    rounded solo append-icon="mdi-send" @click:append="search"
                                    ref="input-nprocesso" hide-details >
                                </v-text-field> -->
                                <v-textarea class="pt-0 pb-2 px-2" background-color="blue-grey lighten-5" style="border-radius: 20px"
                                label="O que deseja buscar em um parecer?" auto-grow solo hide-details rows="1" row-height="9" max-height="300px" append-icon="mdi-send"
                                    v-model="searchInput" @click:append="search()" :loading="loading" loader-height="4">
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-row>
                <!-- <v-row v-if="loading" justify="center" class="px-0">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-row> -->
                <v-slide-y-transition
                    class="py-0"
                    group
                    v-if="!loading && answers.length"
                >

                    <v-row v-for="answer in answers" justify="center" class="py-2" :key="answer.Tittle">
                        <v-card @click="showParecer(answer)" width="100%" outlined>
                            <v-card-title style="word-break: break-word;">
                                {{ answer.Tittle }}
                            </v-card-title>
                            <v-card-text>
                                {{ answer.Summary }}
                            </v-card-text>
                        </v-card>
                        <!-- <span>{{ selectedDocument.id }} {{ answer.id }} {{ printParecer }} {{ selectedDocument.id == answer.id && printParecer }}</span> -->
                        <vue-pdf-embed 
                            ref="pdfRef"
                            v-if="selectedDocument.id == answer.id && printParecer" 
                            @progress="teste"
                            @loading-failed="teste"
                            @rendering-failed="teste"
                            @rendered="updatePageCount"
                            :key="answer.id + documentKey + 'false'"
                            :page="page"
                            :source="urlbackend+'/llm/getPDF/?filename='+selectedDocument.pdf"
                            style="width: -webkit-fill-available;"
                            
                        >
                        </vue-pdf-embed>
                        <v-btn :disabled="page == 1" v-if="selectedDocument.id == answer.id && printParecer"  @click="prevPage()" color="primary"> <v-icon>mdi-chevron-left</v-icon> </v-btn>
                        <v-btn :disabled="page == pageCount" v-if="selectedDocument.id == answer.id && printParecer"  @click="nextPage()" color="primary" > <v-icon>mdi-chevron-right</v-icon> </v-btn>
                        <v-btn text color="primary" v-if="selectedDocument.id == answer.id && printParecer" @click="$refs.pdfRef[0].download()">download</v-btn>
                    </v-row>

                </v-slide-y-transition>
                
            </v-col>
            <v-scroll-y-reverse-transition>
                
                <v-col v-if="showChat" cols="4" 
                style=" z-index: 20;
                        height: 80vh;
                        bottom: 10vh;
                        right: 2vw;
                        position:fixed;
                        align-content: center;
                        overflow-y: scroll;
                        background-color: #E3F2FD;
                        border-radius: 20px;">
                    <Chati :key="chatiKey" :document="selectedDocument.Tittle" :insideChat="true"></Chati>
                </v-col>
            </v-scroll-y-reverse-transition>
        </v-row>

        <v-btn
            @click="showChat = !showChat; $refs.pdfRef[0].render()"
            fab
            large
            dark
            bottom
            right
            color="primary"
            style="position: fixed; top: 90vh;right:2vw"
        >
            <v-icon>mdi-message-outline</v-icon>
        </v-btn>

    </v-container>
</template>


<script>
import Chati from '@/components/Chati.vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import axios from 'axios'
const urlbackend = process.env.VUE_APP_API_URL
// import pdfContent from '@/assets/pdfContent.pdf'
export default {
    name: 'SearchView',
    data() {
        return {
            showChat: false,
            pageCount: 0,
            page: 1,
            searchInput: '',
            results: [],
            answers: [],
            printParecer: false,
            selectedDocument: {},
            chatiKey: '',
            loading: false,
            documentKey: '',
            urlbackend: urlbackend
        }
    },
    components: {
        Chati,
        VuePdfEmbed
    },
    methods: {
        updatePageCount() {
            console.log(this.$refs.pdfRef[0].data)
            this.pageCount = this.$refs.pdfRef[0].pageCount
        },
        prevPage() {
            console.log(this.page)
            if(this.page > 1)
                this.page = this.page - 1

        },
        nextPage() {
            console.log(this.$refs.pdfRef)
            if(this.page < this.pageCount)
                this.page = this.page + 1

        },
        teste(arg){
            console.log("update pdf",arg)
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async search() {
            this.loading = true
            axios.post(urlbackend + "/llm/search/", {message: this.searchInput, wait_scraper: true, model: "LlamaTest2"}).then((res) => {
                console.log(res.data)
                this.answers = res.data.map((el,index) => {return {...el, id: index}})
                console.log(this.answers)
                this.loading = false
            }).catch((err) => {
                console.log(err)
            })
          /*  this.answers = [{
                name: 'teste',
                summary: 'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                url: 'teste',
                id: 1,
                pdfContent: 'https://pdfobject.com/pdf/sample.pdf'
            },
            {
                name: 'teste 2',
                summary: 'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                url: 'teste',
                id: 2,
                pdfContent: 'https://pdfobject.com/pdf/sample.pdf'
            },
            {
                name: 'teste 3',
                summary: 'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                url: 'teste',
                id: 3,
                pdfContent: 'https://pdfobject.com/pdf/sample.pdf'
            },
            {
                name: 'teste 4',
                summary: 'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
                url: 'teste',
                id: 4,
                pdfContent: 'https://pdfobject.com/pdf/sample.pdf'
            }]*/
        },
        async showParecer(answer) {
          
            this.printParecer = false
            this.selectedDocument = {}
            console.log(this.selectedDocument.id == answer.id, this.selectedDocument.id != answer.id)
            if(!(this.selectedDocument.id == answer.id)){
                await axios.get(urlbackend+'/llm/getPDF/?filename='+answer.pdf).then((res) => {
                    console.log(res.data)
                    console.log(answer)
                    this.page = 1
                    this.selectedDocument.pdfContent = res.data
                    this.printParecer = true

                    this.selectedDocument = answer
                    this.chatiKey = 'chati-'+answer.id
                    this.documentKey = 'pdf-'+answer.id

                }).catch((err) => {
                    console.log(err)
                })
                
            }
            console.log(this.printParecer)
        }
    },
}
</script>
<style>
.v-input__prepend-inner {
  margin: 0px !important;
}

.v-textarea textarea {
  max-height: 200px !important
}

.popup-container {
  height: 100%;
  background-color: #E3F2FD;
  padding-bottom: 40px;
  margin: 10px;
  border-radius: 20px;
}
</style>